import React, { useEffect, useState } from "react";
import { usePastPatrolling } from "../../../../../libs/data-access";
import useFiltersSelectionData from "../../../../../hooks/useFiltersSelectionData";
import {
  IPaginationFilter,
  IUserProfilePatrol,
  IPastActivityPayload,
  IUserProfilePatrolPayload,
  ITraveledPath,
} from "../../../../../libs/data-access/types";
import { showToast } from "../../../../../libs/data-access/utility";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import useMedia from "../../../../../libs/data-access/src/Media/useMedia";

export default function useUserProfilePatrolAndNakka() {
  const {
    postPastPatrollingData,
    getPastPatrollingData,
    patchPastPatrollingData,
  } = usePastPatrolling();
  const { postMediaData } = useMedia();

  const param = useParams();
  const {
    allZoneListForSelection,
    allDistrictsListForSelection,
    allRangeListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    liveStatsPayload,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  } = useFiltersSelectionData();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  // const [paginationCurrentPage, setPaginationCurrentPage] = React.useState(1);
  const [isMorePage, setIsMorePage] = React.useState(false);
  const [isLoadingPath, setIsLoadingPath] = React.useState(false);
  const [isDisableNextButton, setIsDisableNextButton] = React.useState(false);
  const [isMapViewable, setIsMapViewable] = React.useState(false);

  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [activityType, setActivityType] = React.useState("PATROL");
  let pageLimit = 10;
  const [paginationAndFilterPayload, setPaginationAndFilterPayload] =
    React.useState<IPaginationFilter>({
      paginationAction: "INITIAL_PAGE",
      maxLimit: pageLimit,
      id: null,
      sortingOrder: "DESC",
    });

  const [pastActivityList, setPastActivityList] = React.useState<
    IUserProfilePatrol[]
  >([]);
  const [selectedPastActivity, setSelectedPastActivity] =
    React.useState<IUserProfilePatrol>({} as IUserProfilePatrol);

  const [traveledPathForPolyline, setTraveledPathForPolyline] = useState<
    {
      lat: number;
      lng: number;
    }[]
  >([]);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 20.5937,
    lng: 78.9629,
  });
  const ToCaptureRef = React.useRef<any>();

  useEffect(() => {
    getPastActivityList({
      paginationFilterRequest: paginationAndFilterPayload,
      policePersonId: param?.id ? +param?.id : 0,
    });
  }, []);
  const getPastActivityList = async (filter: IUserProfilePatrolPayload) => {
    setIsLoading(true);
    const response = await postPastPatrollingData(`for-user-dashboard`, filter);
    if (response?.isSuccess) {
      if (response?.data?.length) {
        setPastActivityList(response?.data);
        if (filter?.paginationFilterRequest?.paginationAction == "NEXT_PAGE") {
          setCurrentPageNo(currentPageNo + 1);
        }
        if (
          filter?.paginationFilterRequest?.paginationAction == "PREVIOUS_PAGE"
        ) {
          setCurrentPageNo(currentPageNo - 1);
        }
        if (
          filter?.paginationFilterRequest?.paginationAction == "INITIAL_PAGE"
        ) {
          setCurrentPageNo(1);
        }
        if (response.data?.length < pageLimit) {
          setIsDisableNextButton(true);
        } else {
          setIsDisableNextButton(false);
        }
      } else {
        setCurrentPageNo(1);
        setIsDisableNextButton(true);
        setPastActivityList([]);
      }
    } else {
      showToast({
        type: "error",
        message: "Fail to get",
      });
      setPastActivityList([]);
    }
    setIsLoading(false);
  };

  const getTraveledPath = async (patrollingId: number) => {
    setIsLoadingPath(true);
    const response = await getPastPatrollingData(
      `patrol-travelled-path?patrolHistoryId=${patrollingId}`
    );
    if (response?.isSuccess) {
      let tPath = response?.data.map((item: ITraveledPath) => ({
        lat: Number(item?.latitude),
        lng: Number(item.longitude),
      }));
      setTraveledPathForPolyline(tPath);
      setDefaultCenter({
        lat: tPath[0]?.lat,
        lng: tPath[0]?.lng,
      });
    } else {
      showToast({
        type: "error",
        message: "Fail to get",
      });
    }
    setIsLoadingPath(false);
  };

  const captureScreenshot = async (patrollingId: number) => {
    try {
      const canvas = await html2canvas(ToCaptureRef.current, {
        useCORS: true,
      });
      canvas.toBlob((blob) => {
        if (blob) {
          if (blob.type.startsWith("image/")) {
            handleUploadMedia(patrollingId, blob);
          } else {
            console.error("Blob is not an image.");
          }
        } else {
          console.error("Failed to create Blob.");
        }
      }, "image/png");
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  const handleUploadMedia = async (id: string | number, blob: any) => {
    const formData = new FormData();
    formData.append("image", blob, "screenshot.png");
    let saveMediaRes = await postMediaData(`save-image`, formData);
    if (saveMediaRes?.isSuccess) {
      let payload = {
        activityId: id,
        mediaId: saveMediaRes?.data?.mediaId,
      };
      let res = await patchPastPatrollingData(
        `attach-travelled-path-image`,
        payload
      );
      console.log("res", res);
      if (res?.isSuccess) {
        setSelectedPastActivity({
          ...selectedPastActivity,
          screenShotId: saveMediaRes?.data?.mediaId,
        });
        let pastActivities = pastActivityList;
        let index = pastActivities.findIndex((item) => item.activityId == id);
        pastActivities[index] = {
          ...pastActivities[index],
          screenShotId: saveMediaRes?.data?.mediaId,
        };
        setPastActivityList([...pastActivities]);
        console.log("past Activity List", pastActivityList);
      }
    } else {
      console.log("Error");
    }
  };

  return {
    isLoading,
    isLoadingPath,
    isOpenDrawer,
    setIsOpenDrawer,
    pageLimit,
    searchValue,
    setSearchValue,
    isMorePage,
    activityType,
    setActivityType,
    pastActivityList,
    getPastActivityList,
    isDisableNextButton,
    allZoneListForSelection,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    liveStatsPayload,
    handleGetAllZones,
    handleUploadMedia,
    handleGetPoliceStation,
    handleGetChildren,
    handleGetOtherFilterSelectionData,
    currentPageNo,
    setCurrentPageNo,
    selectedPastActivity,
    setSelectedPastActivity,
    setPaginationAndFilterPayload,
    defaultCenter,
    traveledPathForPolyline,
    getTraveledPath,
    isMapViewable,
    ToCaptureRef,
    setIsMapViewable,
    captureScreenshot,
  };
}
