import { Loader, Modal, Table } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react'
import useSosList from './hooks/useSosList';
import { ISosList } from '../../libs/data-access/types';
import EPersonalProfileLoader from '../avatar/EPersonalProfileLoader';
import { ETable } from '../table/ETable';
import { useNavigate } from 'react-router';
import { AppContext } from '../../AppContext';

interface IProps {
    isOpen: boolean;
    onClose: () => void
}
export default function SosList({
    isOpen,
    onClose
}: IProps) {

  const navigate = useNavigate();

  const { centerLatAndLong, setCenterLatAndLong, setZoomLevel } = useContext(AppContext);

  const {
    isLoading,
    sosList,
    handleGetActiveSosList
  } = useSosList();


  useEffect(() => {
    if(isOpen){
      handleGetActiveSosList()
    }
  },[isOpen])

  return (
    <Modal
    opened={isOpen}
    onClose={() => {onClose()
    }}
    size={"xl"}
    title="Active SOS List"
    radius={"lg"}
  >
    {
          <ETable>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Phone Number</Table.Th>
              <Table.Th>Police Station</Table.Th>
              {/* <Table.Th></Table.Th> */}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {isLoading ? (
              <Table.Tr>
                <Table.Td colSpan={6}>
                  <div className="flex w-full p-14 justify-center items-center">
                    <Loader size={30} />
                  </div>
                </Table.Td>
              </Table.Tr>
            ) : (
              sosList.map((data, index) => (
                <Table.Tr
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    onClose();
                    setCenterLatAndLong({
                      lat: data?.latitude,
                      lng: data?.longitude,
                    });
                    setZoomLevel(17);
                  navigate(`tracking?persist=${true}`);
                  }}
                >
                  <Table.Td>
                    <div className="flex flex-row gap-2 items-center capitalize">
                      {/* <Avatar /> */}
                      {data.name?.firstName ?? ""}
                      {" " + data.name?.lastName ?? ""}
                    </div>
                  </Table.Td>
                  <Table.Td>{data.phoneNumber ?? ""}</Table.Td>
                  <Table.Td>{data.policeStationName ?? ""}</Table.Td>
                </Table.Tr>
              ))
            )}
          </Table.Tbody>
        </ETable>
    }
    </Modal>
  )
}
