import React, { useEffect, useState } from "react";
import {
  IPaginationFilter,
  IPastActivityList,
  IPastActivityPayload,
  ITraveledPath,
  IUserPAginationAndFilterPayload,
} from "../../../libs/data-access/types";
import {
  useLivePatrolling,
  usePastPatrolling,
} from "../../../libs/data-access";
import { showToast } from "../../../libs/data-access/utility";
import useFiltersSelectionData from "../../../hooks/useFiltersSelectionData";
import html2canvas from "html2canvas";
import useMedia from "../../../libs/data-access/src/Media/useMedia";

export default function usePastActivity() {
  const {
    postPastPatrollingData,
    getPastPatrollingData,
    patchPastPatrollingData,
  } = usePastPatrolling();
  const { postMediaData } = useMedia();
  const {
    allZoneListForSelection,
    allDistrictsListForSelection,
    allRangeListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    liveStatsPayload,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  } = useFiltersSelectionData();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingPath, setIsLoadingPath] = React.useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  // const [paginationCurrentPage, setPaginationCurrentPage] = React.useState(1);
  const [isMorePage, setIsMorePage] = React.useState(false);
  const [isMapViewable, setIsMapViewable] = React.useState(false);
  const [isDisableNextButton, setIsDisableNextButton] = React.useState(false);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [activityType, setActivityType] = React.useState("PATROL");
  const [activityTypeForDrawer, setActivityTypeForDrawer] =
    React.useState("PATROL");
  let pageLimit = 10;
  const [paginationAndFilterPayload, setPaginationAndFilterPayload] =
    React.useState<IPaginationFilter>({
      paginationAction: "INITIAL_PAGE",
      maxLimit: pageLimit,
      id: null,
      sortingOrder: "DESC",
    });

  const [pastActivityList, setPastActivityList] = React.useState<
    IPastActivityList[]
  >([]);
  const [selectedPastActivity, setSelectedPastActivity] =
    React.useState<IPastActivityList>({} as IPastActivityList);

  const [traveledPathForPolyline, setTraveledPathForPolyline] = useState<
    {
      lat: number;
      lng: number;
    }[]
  >([]);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 20.5937,
    lng: 78.9629,
  });
  const ToCaptureRef = React.useRef<any>();

  useEffect(() => {
    getPastActivityList({
      ...liveStatsPayload,
      paginationFilterRequest: paginationAndFilterPayload,
      activityType: activityType,
    });
  }, []);
  const getPastActivityList = async (filter: IPastActivityPayload) => {
    setIsLoading(true);
    const response = await postPastPatrollingData(`for-dashboard`, filter);
    if (response?.isSuccess) {
      setActivityTypeForDrawer(activityType);
      if (response?.data?.length) {
        setPastActivityList(response?.data);
        if (filter?.paginationFilterRequest?.paginationAction == "NEXT_PAGE") {
          setCurrentPageNo(currentPageNo + 1);
        }
        if (
          filter?.paginationFilterRequest?.paginationAction == "PREVIOUS_PAGE"
        ) {
          setCurrentPageNo(currentPageNo - 1);
        }
        if (
          filter?.paginationFilterRequest?.paginationAction == "INITIAL_PAGE"
        ) {
          setCurrentPageNo(1);
        }
        if (response.data?.length < pageLimit) {
          setIsDisableNextButton(true);
        } else {
          setIsDisableNextButton(false);
        }
      } else {
        setCurrentPageNo(1);
        setIsDisableNextButton(true);
        setPastActivityList([]);
      }
    } else {
      showToast({
        type: "error",
        message: "Fail to get",
      });
      setPastActivityList([]);
    }
    setIsLoading(false);
  };

  const getTraveledPath = async (patrollingId: number) => {
    setIsLoadingPath(true);
    const response = await getPastPatrollingData(
      `patrol-travelled-path?patrolHistoryId=${patrollingId}`
    );
    if (response?.isSuccess) {
      let tPath = response?.data.map((item: ITraveledPath) => ({
        lat: Number(item?.latitude),
        lng: Number(item.longitude),
      }));
      setTraveledPathForPolyline(tPath);
      setDefaultCenter({
        lat: tPath[0]?.lat,
        lng: tPath[0]?.lng,
      });
    } else {
      showToast({
        type: "error",
        message: "Fail to get",
      });
    }
    setIsLoadingPath(false);
  };

  const captureScreenshot = async (patrollingId: number) => {
    try {
      const canvas = await html2canvas(ToCaptureRef.current, {
        useCORS: true,
      });

      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        if (blob) {
          if (blob.type.startsWith("image/")) {
            // console.log("Blob type is image:", blob.type);
            // const blobUrl = URL.createObjectURL(blob);
            // const a = document.createElement("a");
            // a.href = blobUrl;
            // a.download = "screenshot.png";
            // document.body.appendChild(a);
            // a.click();
            handleUploadMedia(patrollingId, blob);
            // document.body.removeChild(a);
          } else {
            console.error("Blob is not an image.");
          }
        } else {
          console.error("Failed to create Blob.");
        }
      }, "image/png");
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  const handleUploadMedia = async (id: string | number, blob: any) => {
    const formData = new FormData();
    formData.append("image", blob, "screenshot.png");
    let saveMediaRes = await postMediaData(`save-image`, formData);
    if (saveMediaRes?.isSuccess) {
      let payload = {
        activityId: id,
        mediaId: saveMediaRes?.data?.mediaId,
      };
      let res = await patchPastPatrollingData(
        `attach-travelled-path-image`,
        payload
      );
      console.log("res", res);

      if (res?.isSuccess) {
        setSelectedPastActivity({
          ...selectedPastActivity,
          screenShotId: saveMediaRes?.data?.mediaId,
        });
        let pastActivities = pastActivityList;
        let index = pastActivities.findIndex((item) => item.activityId == id);
        pastActivities[index] = {
          ...pastActivities[index],
          screenShotId: saveMediaRes?.data?.mediaId,
        };
        setPastActivityList([...pastActivities]);
      }
    } else {
      console.log("Error");
    }
  };

  return {
    isLoading,
    isLoadingPath,
    isMapViewable,
    ToCaptureRef,
    setIsMapViewable,
    isOpenDrawer,
    defaultCenter,
    traveledPathForPolyline,
    getTraveledPath,
    setIsOpenDrawer,
    pageLimit,
    searchValue,
    setSearchValue,
    isMorePage,
    activityType,
    setActivityType,
    activityTypeForDrawer,
    pastActivityList,
    getPastActivityList,
    isDisableNextButton,
    allZoneListForSelection,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    liveStatsPayload,
    currentPageNo,
    selectedPastActivity,
    captureScreenshot,
    handleGetAllZones,
    handleGetPoliceStation,
    handleGetChildren,
    handleGetOtherFilterSelectionData,
    setCurrentPageNo,
    setSelectedPastActivity,
    setPaginationAndFilterPayload,
  };
}
