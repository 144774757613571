import React, { useContext, useEffect, useState } from 'react'
import { IMAGE_BASE_URL } from '../../libs/data-access/constant';
import Cookies from 'js-cookie';
import { Avatar } from '@mantine/core';
import { AppContext } from '../../AppContext';


interface IProps {
    avtarId: string | number,
    size?: "sm" | "md" | "lg"
}

// const token = Cookies.get("token");
export default function EPersonalProfileLoader({
    avtarId,
    size
}: IProps) {

  const {token} = useContext(AppContext);

    const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      setImageSrc('');
      try {
        const response = await fetch(`${IMAGE_BASE_URL}${avtarId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            // other headers if needed
          },
        });

        if (response.ok) {
          const imageData = await response.blob();
          const imageUrl = URL.createObjectURL(imageData);
          setImageSrc(imageUrl);
        } else {
          // handle error
          console.error('Error loading image:', response.status, response.statusText);
        }
      } catch (error) {
        // handle network error
        console.error('Network error:', error);
      }
    };

    if (avtarId) {
      fetchImage();
    }
  }, [token, avtarId]);


  return (
    <Avatar src={imageSrc} size={size} />
  )
}
