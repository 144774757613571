import { MapIcon } from "@heroicons/react/24/solid";
import Dashboard from "../pages/dashboard";
import Tracking from "../pages/tracking";
import { ReactElement } from "react";
import {
  ChartPieIcon,
  CogIcon,
  RectangleStackIcon,
  UsersIcon,
  PresentationChartLineIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import UserManagement from "../pages/user-management";
import ReportAnalysis from "../pages/report-analysis";
import Settings from "../pages/settings";
import Profile from "../pages/user-management/profile";
import PastActivity from "../pages/pastActivity";
import Transfer from "../pages/transfer";

export const ROLES = {
  STATE: "State",
  DISTRICT: "District",
  SUBDIVISION: "SubDivision",
  POLICE_STATION: "PoliceStation",
};

export interface IRoute {
  path: string;
  label: string;
  element: any;
  icon?: any;
  isSubmenu?: boolean;
  submenu?: IRoute[];
  rolesAllowed?: string[];
}

export const ROUTES: {
  ALL_ROUTES: IRoute[];
} = {
  ALL_ROUTES: [
    {
      path: "/",
      label: "Dashboard",
      element: Dashboard,
      icon: RectangleStackIcon,
      rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
    },
    {
      path: "/tracking",
      label: "Live Tracking",
      element: Tracking,
      icon: MapIcon,
      rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
    },
    {
      path: "/past-activity",
      label: "Past Activity",
      element: PastActivity,
      icon: PresentationChartLineIcon,
      rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
    },
    {
      path: "/user-management",
      label: "User Management",
      element: UserManagement,
      icon: UsersIcon,
      rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
      // submenu: [
      //   {
      //     path: "/user-management/profile",
      //     label: "Profile",
      //     element: Profile,
      //     rolesAllowed: [ROLES.STATE],
      //     isSubmenu: true,
      //   },
      // ],
    },
    {
      path: "/user-management/profile/:id",
      label: "Profile",
      element: Profile,
      rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
      isSubmenu: true,
    },
    {
      path: "/report",
      label: "Report & Analysis",
      element: ReportAnalysis,
      icon: ChartPieIcon,
      rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
    },
    // {
    //   path: "/transfer",
    //   label: "Transfer ",
    //   element: Transfer,
    //   icon: ArrowPathIcon,
    //   rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
    // },
    // {
    //   path: "/settings",
    //   label: "Settings",
    //   element: Settings,
    //   icon: CogIcon,
    //   rolesAllowed: [ROLES.STATE, ROLES.DISTRICT],
    // },
  ],
};
