import { Select } from "@mantine/core";
import React from "react";
import { ISelectBoxItems } from "../../utils/types";

interface ESelectProps {
  label?: string;
  placeholder?: string;
  description?: string;
  disabled?: boolean;
  value?: string | null | undefined;
  error?: string;
  marginTop?: boolean;
  data?: string[] | ISelectBoxItems[];
  onChange?: (e: any) => void;
}

function ESelect({
  label,
  placeholder,
  description,
  disabled,
  error,
  value,
  marginTop = true,
  data,
  onChange,
}: ESelectProps) {
  return (
    <Select
      mt={marginTop ? "sm" : 0}
      size="sm"
      label={label && <div className={`font-semibold mb-1`}>{label}</div>}
      placeholder={placeholder}
      nothingFoundMessage="Nothing found"
      // searchable
      allowDeselect={false}
      disabled={disabled}
      value={value}
      data={data}
      inputWrapperOrder={["label", "error", "input", "description"]}
      onChange={onChange}
    />
  );
}

export default React.memo(ESelect);
