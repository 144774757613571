import { useContext, useState } from "react";
import { useAuth } from "../../../libs/data-access";
import { ILoginPayload } from "../../../utils/types";
import { AppContext } from "../../../AppContext";
import Cookies from "js-cookie";
import { ILoggedInUserInfo } from "../../../libs/data-access/types";
import { showToast } from "../../../libs/data-access/utility";
import useFirebase from "../../../utils/useFirebase";

export default function useAuthentication() {
  const { postAuthData } = useAuth();

  const {
    setIsLoggedIn,
    setToken,
    setRole,
    setLoggedInUserInfo,
    setRefreshToken,
    firebaseDeviceToken,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userNameAndPassword, setUserNameAndPassword] = useState<ILoginPayload>(
    {
      userName: "",
      password: "",
      deviceInfoInput: {
        deviceToken: "",
      },
    }
  );
  const [logOutFromOldDevice, setLogOutFromOldDevice] =
    useState<boolean>(false);

  let roleByUserId: { [key: string]: string } = {
    "10000": "State",
    "100000": "District",
    "1000000": "SubDivision",
  };

  const handleLoginPayload = (key: string, value: string) => {
    setUserNameAndPassword((prev) => ({ ...prev, [key]: value }));
  };

  const handleLogin = async (endPoint: string = "signin-with-password") => {
    setIsLoading(true);

    let payload = {
      userName: userNameAndPassword?.userName,
      password: userNameAndPassword?.password,
      deviceInfoInput: {
        deviceToken: firebaseDeviceToken,
      },
    };

    let res = await postAuthData(endPoint, payload);
    if (res?.isSuccess) {
      const token = res?.data?.accessToken;
      const refreshToken = res?.data?.refreshToken;
      const loggedInUser: ILoggedInUserInfo = res?.data?.user;

      Cookies.set("isLoggedIn", "true", {
        // secure: true,
        // sameSite: "strict",
      });
      Cookies.set("token", token, {
        // secure: true,
        // sameSite: "strict",
      });
      Cookies.set("loggedInUser", JSON.stringify(loggedInUser), {
        // secure: true,
        // sameSite: "strict",
      });
      Cookies.set("refreshToken", refreshToken, {
        // secure: true,
        // sameSite: "strict",
      });
      Cookies.set("refreshToken", refreshToken, {
        // secure: true,
        // sameSite: "strict",
      });
      const userRole = Cookies.set(
        "role",
        userNameAndPassword?.userName
          ? roleByUserId[userNameAndPassword?.userName] ?? "State"
          : "State",
        {
          // secure: true,
          // sameSite: "strict",
        }
      );
      setLoggedInUserInfo(loggedInUser);
      setToken(token);
      localStorage.setItem("fcmToken", firebaseDeviceToken);
      setRefreshToken(refreshToken);
      setIsLoggedIn(true);
      // localStorage.setItem("role");
    } else {
      if (res?.error?.status === 412) {
        setLogOutFromOldDevice(true);
      } else {
        showToast({
          type: "error",
          title: "Please try again",
          message: res.message || "Something went wrong !",
        });
      }
    }
    setIsLoading(false);
  };

  const handleLoginExclusively = () => {
    handleLogin("signin-with-password-exclusively");
    setLogOutFromOldDevice(true);
  };

  return {
    logOutFromOldDevice,
    userNameAndPassword,
    isLoading,
    handleLogin,
    handleLoginPayload,
    setLogOutFromOldDevice,
    setUserNameAndPassword,
    handleLoginExclusively,
  };
}
