import React, { useEffect } from "react";
import useDashboardData from "../../../libs/data-access/src/Dashboard/useDashboardData";
import {
  IUserManagementUserList,
  IUserPAginationAndFilterPayload,
} from "../../../libs/data-access/types";
import { showToast } from "../../../libs/data-access/utility";
import useFiltersSelectionData from "../../../hooks/useFiltersSelectionData";

export default function useUserManagement() {
  const { postDashboardData } = useDashboardData();
  const {
    allZoneListForSelection,
    allDistrictsListForSelection,
    allRangeListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    liveStatsPayload,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  } = useFiltersSelectionData();

  let pageLimit = 10;
  const [isLoading, setIsLoading] = React.useState(false);
  const [userList, setUserList] = React.useState<IUserManagementUserList[]>([]);
  const [isActivePreviousButton, setIsActivePreviousButton] =
    React.useState(false);
  const [isDisableNextButton, setIsDisableNextButton] = React.useState(false);
  const [currentPageNo, setCurrentPageNo] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [paginationAndFilterPayload, setPaginationAndFilterPayload] =
    React.useState<IUserPAginationAndFilterPayload>({
      paginationFilterRequest: {
        paginationAction: "INITIAL_PAGE",
        maxLimit: pageLimit,
        id: null,
        sortingOrder: "DESC",
      },
      nameOrPostion: null,
    });

  useEffect(() => {
    if (searchValue && searchValue?.length > 2) {
      setCurrentPageNo(1);
      fetchUserList({
        ...paginationAndFilterPayload,
        nameOrPostion: searchValue,
      });
    } else if (!searchValue) {
      fetchUserList(paginationAndFilterPayload);
    }
  }, [searchValue]);

  const fetchUserList = async (filter: IUserPAginationAndFilterPayload) => {
    setIsLoading(true);
    const res = await postDashboardData(`all-police-personnel`, filter);
    if (res.isSuccess) {
      setUserList(res?.data);
      if (res?.data?.length) {
        if (filter?.paginationFilterRequest?.paginationAction == "NEXT_PAGE") {
          setCurrentPageNo(currentPageNo + 1);
        }
        if (
          filter?.paginationFilterRequest?.paginationAction == "PREVIOUS_PAGE"
        ) {
          setCurrentPageNo(currentPageNo - 1);
        }
      } else {
        setIsDisableNextButton(true);
      }
    } else {
      showToast({
        type: "error",
        title: "Fail to get user list",
        message: res?.message || "Fail to get user list",
      });
      setUserList([]);
    }
    setIsLoading(false);
  };
  return {
    isLoading,
    userList,
    pageLimit,
    isActivePreviousButton,
    isDisableNextButton,
    paginationAndFilterPayload,
    fetchUserList,
    setPaginationAndFilterPayload,
    setIsActivePreviousButton,
    setIsDisableNextButton,
    currentPageNo,
    setCurrentPageNo,
    searchValue,
    setSearchValue,
    liveStatsPayload,
    allZoneListForSelection,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  };
}
