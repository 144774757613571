import { useState } from "react";
import useDashboardData from "../../../libs/data-access/src/Dashboard/useDashboardData";
import { ISosList } from "../../../libs/data-access/types";

export default function useSosList() {
  const { getDashboardData } = useDashboardData();
  const [sosList, setSosList] = useState<ISosList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetActiveSosList = async () => {
    setIsLoading(true);
    setSosList([]);
    let res = await getDashboardData(`sos-list`);
    if (res?.isSuccess) {
      setSosList(res.data);
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    sosList,
    handleGetActiveSosList,
  };
}
