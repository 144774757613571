import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import config from "../firebase";
import { showToast } from "../libs/data-access/utility";
import { useContext } from "react";
import { AppContext } from "../AppContext";
import { usePolicePersonnel } from "../libs/data-access";

export default function useFirebase() {
  const {
    isLoggedIn,
    firebaseDeviceToken,
    setFirebaseDeviceToken,
    setSosActive,
  } = useContext(AppContext);

  const { patchPolicePersonalData } = usePolicePersonnel();

  const handleFirebaseNotifications = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        const app = initializeApp(config);

        const messaging = getMessaging(app);

        getToken(messaging, {
          vapidKey:
            "BPKHHNn44TifzavD0f37__Y_Uj4wMsMPvc8Otn4t85G6Gs3H8HmfWRjag0vTlvTyUVdFczWVqq3hsViWDeoq4nk",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("current token", currentToken);
              setFirebaseDeviceToken(currentToken);
              if (isLoggedIn) {
                const fcmToken = localStorage.getItem("fcmToken");
                if (fcmToken !== currentToken) {
                  updateDeviceToken(currentToken);
                  localStorage.setItem("fcmToken", currentToken);
                }
              }
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });

        onMessage(messaging, (payload) => {
          setSosActive(payload?.data?.sosStatus);
          showToast({
            type: "warning",
            title: "SOS",
            message: payload?.data?.message || "",
          });
        });
      } else {
        console.log("Unable to get permission to notify." + permission);
      }
    });
  };

  const updateDeviceToken = async (token: string) => {
    try {
      const res = await patchPolicePersonalData(
        `update-device-token?token=${token}`,
        {}
      );
      if (res?.isSuccess) {
        console.log("Response", res);
      } else {
        console.log("Error", res?.error);
      }
    } catch (error) {
      console.error("An error occurred while updating device token: ", error);
    }
  };

  return {
    handleFirebaseNotifications,
  };
}
