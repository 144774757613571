export interface IResponse<T> {
  message: string;
  data: T;
  extraDetails?: any;
}

export interface IResponseFormat<T> {
  isSuccess: boolean;
  data: T;
  error: T;
  message?: string;
  extraDetails?: any;
}

export const res: IResponseFormat<undefined> = {
  isSuccess: false,
  data: undefined,
  error: undefined,
  message: "",
};

export interface ILoggedInUserInfo {
  [x: string]: any;
  createdDate: string;
  lastModifiedDate: string;
  createdBy: string;
  lastModifiedBy: string;
  id: number;
  userName: string;
  password: string;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  professionalEmail: string;
  version: number;
  botUser: boolean;
  fatherName: string;
  dateOfBirth: string;
  policeStationName: string;
  subStationName: string;
  policeStationId: string;
}

export interface IZoneAndChildren {
  id: number;
  name: string;
}
export interface IPoliceStations {
  PoliceStationId: number;
  PoliceStationName: string;
}

export interface ILiveStats {
  livePatrollingCount: number;
  livePatrollingPersonnelCount: number;
  liveCheckPointCount: number;
  liveCheckPointPersonnelCount: number;
}
export interface ILiveStatsExtraDetails {
  latitude: number;
  longitude: number;
}

export interface IActivityStats {
  patrolStats: {
    "Traffic Police": number;
    SSP: number;
    "Patrolling Unit": number;
    District: number;
  };
  checkPointStats: {
    Temporary: number;
    Permanent: number;
    "Hi-Tech": number;
  };
}

export interface IDistrictStats {
  [district: string]: number;
}

export interface IDistrictWiseStats {
  aggregatedData: IDistrictStats;
  highestStats: IDistrictStats;
  lowestStats: IDistrictStats;
  totalCount: number;
}

export interface ILiveTrackingData {
  id: number;
  activityType: string;
  lon: number;
  lat: number;
  heading: number;
  iconPath: string;
  activity: string;
  sosEnabled: boolean;
}

export interface IPatrollingDetails {
  id: number;
  patrolType: string;
  vehicleNumberPlate: string;
  vehicleMakeYear: string;
  vehicleModel: string;
  policePersonnelList: {
    phoneNumber: string;
    avtarID: number;
    name: string;
  }[];
  policeStationName: string;
  patrolStartedAt: string;
  duration: string;
}

export interface ICheckPointDetails {
  id: number;
  checkPointType: string;
  policePersonnelList: {
    policePersonnelId: number;
    fullName: string;
    phoneNumber: string;
  }[];
  policeStationName: string;
  startedAt: string;
}

{
  /* PAST ACTIVITY INTERFACE */
}

export interface IPastActivityList {
  activityId: number;
  dateTimeDuration: string;
  activityType: string;
  observation: string;
  screenShotId: string;
  companionDetails: ICompanionDetails[];
  district: {
    id: number;
    name: string;
  };
  subDivision: {
    id: number;
    name: string;
  };
  policeStation: {
    id: number;
    briefAddress: string;
    fullAddress: string;
    comesUnderLocationId: number;
  };
  duration: string;
  startAt: string;
  endAt: string;
}

export interface ICompanionDetails {
  firstName: string;
  middleName: string;
  lastName: string;
  avtarId: number;
  userName: string;
  activityInCharge: boolean;
  distanceTravelled: number;
}

{
  /* USER MANAGEMENT ACTIVITY INTERFACE */
}

export type IPaginationFilter = {
  paginationAction: "PREVIOUS_PAGE" | "INITIAL_PAGE" | "NEXT_PAGE";
  maxLimit: number;
  id: number | null;
  sortingOrder: "DESC" | "ASC";
};
export interface IUserPAginationAndFilterPayload {
  paginationFilterRequest: IPaginationFilter;
  nameOrPostion?: string | null;
}

export interface IPastActivityPayload {
  paginationFilterRequest: IPaginationFilter;
  activityType: "PATROL" | string;
  zoneIDs: any[];
  rangeIDs: any[];
  districtIDs: any[];
  subDivisionIDs: any[];
  policeStationIDs: any[];
}

export type IUserProfilePatrolPayload = {
  paginationFilterRequest: IPaginationFilter;
  policePersonId: number;
};

export interface IUserManagementUserList {
  mediaFileId: number;
  policePersonId: number;
  position: string;
  policeStationName: string;
  phoneNumber: string;
  noOfActivity: number;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
}

export interface IUserManagementProfile {
  firstName: string;
  middleName: string;
  lastName: string;
  createdDate: string;
  dateOfBirth: string;
  fatherName: string;
  address: string;
  city: string;
  state: string;
  pinCode: string;
  phoneNumber: string;
  email: string;
  policeStationName: string;
  subStationName: string;
  stationHeadFirstName: string;
  stationHeadMiddleName: string;
  stationHeadLastName: string;
  nakkaCount: string;
  patrolCount: string;
  timeSpent: string;
  distanceCovered: string;
}

export interface IUserProfilePatrol {
  activityId: number;
  activityNumber: number;
  startedAt: string;
  endedAt: string;
  summary: string;
  companionResponses: ICompanionDetails[];
  screenShotId: string;
  duration: string;
  distanceTravelled: string;
  capturedTravelledPath: string;
  activityType: string;
}
export interface ITraveledPath {
  latitude: string;
  longitude: string;
  heading: string;
  locationCaptured: string;
}

export interface ISosList {
  phoneNumber: string;
  createdDate: string;
  latitude: number;
  longitude: number;
  policeStationName: string;
  name: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
}
