import React from "react";

interface IProps {
  label: string;
  value: string;
}

export default function FormInput({ label, value }: IProps) {
  return (
    <div className="border-b border-zinc-200 mt-2">
      <div className="text-slate-500 text-xs font-semibold">
        {label}
      </div>
      <div className="text-neutral-900 text-base pb-2 mt-1">
        {value}
      </div>
    </div>
  );
}
