import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { ECard, ESegment } from "../../../components";
import DistrictWiseBreakDownStatsCard from "./DistrictWiseBreakDownStatsCard";
import useDashboard from "../hooks/useDashboard";
import { LoadingOverlay } from "@mantine/core";

export default function DistrictWiseBreakDownStats({
  handleClickSearch,
}: {
  handleClickSearch: boolean;
}) {
  const {
    isLoadingDistrictWiseBreakDownStats,
    activeTabDistrictWiseBreakDown,
    setActiveTabDistrictWiseBreakDown,
    districtWiseBreakDownGraph,
    districtWiseBreakDownStats,
    handleGetDistrictWiseBreakDownStats,
  } = useDashboard();

  useEffect(() => {
    handleGetDistrictWiseBreakDownStats();
  }, [activeTabDistrictWiseBreakDown, handleClickSearch]);

  return (
    <ECard>
      <ECard.Head title="District-wise Breakdown">
        <div className="flex items-end gap-3">
          <ESegment.Wrapper>
            <ESegment
              label="Patrol"
              isActive={
                activeTabDistrictWiseBreakDown?.patrolOrNakka == "PATROL"
                  ? true
                  : false
              }
              handleClick={() =>
                setActiveTabDistrictWiseBreakDown((prev) => ({
                  ...prev,
                  patrolOrNakka: "PATROL",
                }))
              }
            />
            <ESegment
              label="Nakka"
              isActive={
                activeTabDistrictWiseBreakDown?.patrolOrNakka == "CHECK_POINT"
                  ? true
                  : false
              }
              handleClick={() =>
                setActiveTabDistrictWiseBreakDown((prev) => ({
                  ...prev,
                  patrolOrNakka: "CHECK_POINT",
                }))
              }
            />
          </ESegment.Wrapper>
          <ESegment.Wrapper>
            <ESegment
              label="This Week"
              isActive={
                activeTabDistrictWiseBreakDown?.time == "WEEK" ? true : false
              }
              handleClick={() =>
                setActiveTabDistrictWiseBreakDown((prev) => ({
                  ...prev,
                  time: "WEEK",
                }))
              }
            />
            <ESegment
              label="This Month"
              isActive={
                activeTabDistrictWiseBreakDown?.time == "MONTH" ? true : false
              }
              handleClick={() =>
                setActiveTabDistrictWiseBreakDown((prev) => ({
                  ...prev,
                  time: "MONTH",
                }))
              }
            />
            <ESegment
              label="This Year"
              isActive={
                activeTabDistrictWiseBreakDown?.time == "YEAR" ? true : false
              }
              handleClick={() =>
                setActiveTabDistrictWiseBreakDown((prev) => ({
                  ...prev,
                  time: "YEAR",
                }))
              }
            />
          </ESegment.Wrapper>
        </div>
      </ECard.Head>
      <ECard.Body>
        <div className="relative">
          <LoadingOverlay
            visible={isLoadingDistrictWiseBreakDownStats}
            loaderProps={{
              size: "xl",
              color: "blue",
              variant: "bars",
              children: "Loading...",
            }}
          />
          <ReactApexChart
            options={
              (districtWiseBreakDownGraph.options,
              {
                fill: {
                  colors: ["#5C3EBA"],
                },
              })
            }
            series={districtWiseBreakDownGraph.series}
            type="bar"
            height={420}
            // width={500}
          />
          {/* </LoadingOverlay> */}
        </div>
      </ECard.Body>

      <ECard.Foot>
        <div className="flex-row grid grid-cols-3 gap-2 ">
          {/* <DistrictWiseBreakDownStatsCard
          title={activeTabDistrictWiseBreakDown?.patrolOrNakka =="PATROL" ? "Total No. of Patrols" : "Total No. of Nakkas"}
          loading={isLoadingDistrictWiseBreakDownStats}
          subTitle={districtWiseBreakDownStats?.totalCount}
        /> */}

          <DistrictWiseBreakDownStatsCard
            title={
              activeTabDistrictWiseBreakDown?.patrolOrNakka == "PATROL"
                ? "Most Patrolled"
                : "Most Nakkas"
            }
            loading={isLoadingDistrictWiseBreakDownStats}
            subTitle={
              districtWiseBreakDownStats?.highestStats
                ? Object.keys(
                    districtWiseBreakDownStats?.highestStats
                  )[0]
                : ""
            }
            rightEnd={
              districtWiseBreakDownStats?.highestStats
                ? Object.values(
                    districtWiseBreakDownStats?.highestStats
                  )[0]
                : ""
            }
          />

          <DistrictWiseBreakDownStatsCard
            title={
              activeTabDistrictWiseBreakDown?.patrolOrNakka == "PATROL"
                ? "Least Patrolled"
                : "Least Nakkas"
            }
            loading={isLoadingDistrictWiseBreakDownStats}
            subTitle={
              districtWiseBreakDownStats?.lowestStats
                ? Object.keys(
                    districtWiseBreakDownStats?.lowestStats
                  )[0]
                : ""
            }
            rightEnd={
              districtWiseBreakDownStats?.lowestStats
                ? Object.values(
                    districtWiseBreakDownStats?.lowestStats
                  )[0]
                : ""
            }
          />
        </div>
      </ECard.Foot>
    </ECard>
  );
}
