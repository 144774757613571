import React, { useEffect } from "react";
import "./login.scss";
import {
  EButton,
  EButtonLink,
  EInput,
  ESelect,
  ESpacer,
} from "../../../components";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import Logo from "../../../assets/images/logo.png";
import useAuthentication from "../hooks/useAuthentication";
import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Modal, Select } from "@mantine/core";
import Cookies from "js-cookie";
import useFirebase from "../../../utils/useFirebase";

export default function Login() {
  // const { handleFirebaseNotifications } = useFirebase();
  const {
    isLoading,
    logOutFromOldDevice,
    userNameAndPassword,
    setLogOutFromOldDevice,
    handleLoginPayload,
    handleLogin,
    handleLoginExclusively,
  } = useAuthentication();

  return (
    <div className="bg-login items-center justify-center h-screen flex flex-col gap-3">
      {/* container */}
      <div className="bg-white border-t-4  border-slate-300 relative p-11 rounded-xl w-[480px] shadow-xl flex flex-col gap-6">
        {/* hd */}
        <div className="flex flex-col items-start gap-3">
          <img src={Logo} alt="" className="h-20 w-auto" />
          <div>
            <h1 className="mb-2">Surakhya Portal</h1>
            <p className="muted">Sign in using your HRMS number and password</p>
          </div>
        </div>
        {/* form */}
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevents default form submission behavior
            handleLogin(); // Calls handleLogin() function
          }}
          className=""
        >
          <EInput
            label="HRMS Number"
            placeholder="Enter HRMS number"
            onChange={(val) => {
              handleLoginPayload("userName", "" + val);
            }}
            value={userNameAndPassword?.userName}
          />
          <EInput
            label="Password"
            placeholder="Password"
            onChange={(val) => {
              handleLoginPayload("password", "" + val);
            }}
            value={userNameAndPassword?.password}
            type="password"
            // description="Description"
            // error="Error"
          />

          {/* footer */}
          <div className="flex flex-col gap-2 text-center mt-4">
            <button type="submit"></button>
            <EButton
              loading={isLoading}
              disabled={
                // userNameAndPassword?.userName?.length != 10 ||
                !userNameAndPassword?.password || isLoading
              }
              fullWidth
              onClick={() => {
                handleLogin();
              }}
            >
              <EButton.Label>Sign In</EButton.Label>
            </EButton>
            <div>
              <p className="text-xs muted">
                If you don't have an account, please contact your system
                administrator
              </p>
            </div>
            {/* <EButtonLink label="Forgot Password" /> */}
          </div>
        </form>
      </div>
      <div className="opacity-50 text-white text-xs font-medium">
        Powered by Espranza Innovations
      </div>

      <Modal
        opened={logOutFromOldDevice}
        onClose={() => {
          !isLoading && setLogOutFromOldDevice(false);
        }}
        size={"md"}
        title="Action Required"
        radius={"lg"}
        padding={"xl"}
      >
        {/* inner */}
        <div className="">
          <h2 className="mb-2">Account Already in Use</h2>
          <p className="opacity-80">
            Your account is already logged in on another device. Click below to
            logout other device and continue to login here.
          </p>

          <div className="flex flex-col justify-end gap-2 pt-11">
            <EButton
              variant="danger"
              onClick={() => {
                console.log("login here");
                handleLoginExclusively();
              }}
              loading={isLoading}
              disabled={isLoading}
            >
              Logout Other Device & Continue
            </EButton>
            <EButton
              variant="light"
              onClick={() => {
                setLogOutFromOldDevice(false);
              }}
              disabled={isLoading}
            >
              Cancel
            </EButton>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export const variantsOverlay = {
  hidden: { translateY: 0, opacity: 0.5 },
  visible: {
    translateY: 0,
    opacity: 1,
    transition: {
      type: "spring",
      when: "beforeChildren",
      staggerChildren: 0.15,
    },
  },
};
export const variants = {
  hidden: { translateY: -20, opacity: 0 },
  visible: {
    translateY: 0,
    opacity: 1,
    transition: {
      type: "spring",
      when: "beforeChildren",
      staggerChildren: 0.15,
    },
  },
};
