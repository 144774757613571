import React, { useCallback, useEffect, useState } from "react";
import useUserProfilePatrolAndNakka from "./hooks/useUsderProfilePatrolAndNakka";
import Cookies from "js-cookie";
import { EButton, EPageHeader, ESelect, ETable } from "../../../../components";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Loader,
  LoadingOverlay,
  Modal,
  Table,
  Tooltip,
} from "@mantine/core";
import { IMAGE_BASE_URL } from "../../../../libs/data-access/constant";
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import moment from "moment";
import { GoogleMap, PolylineF, useLoadScript } from "@react-google-maps/api";
import EImage from "../../../../components/image/EImage";
import PersonnelItem from "../../../pastActivity/components/PersonnelItem";
import CompanionDetails from "../../../pastActivity/components/CompanionDetails";



const token = Cookies.get("token");

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const GOOGLE_MAPS_LIBRARIES = ["geometry" as const];

export default function UserProfilePatrolAndNakka() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY || "",
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  // const [map, setMap] = useState(null);


  // const onLoad = useCallback((map: any) => setMap(map), []);


  const {
    isLoading,
    isLoadingPath,
    isOpenDrawer,
    setIsOpenDrawer,
    pastActivityList,
    pageLimit,
    getPastActivityList,
    isDisableNextButton,
    handleGetAllZones,
    handleGetPoliceStation,
    handleGetChildren,
    currentPageNo,
    selectedPastActivity,
    setSelectedPastActivity,
    defaultCenter,
    traveledPathForPolyline,
    getTraveledPath,
    isMapViewable,
    setIsMapViewable,
    ToCaptureRef,
    captureScreenshot
  } = useUserProfilePatrolAndNakka();

  const [map, setMap] = useState(null);
  const [screenshotCaptured, setScreenshotCaptured] = useState(false);

  // const ToCaptureRef = React.useRef<any>();

  const onLoad = useCallback((map: any) => {
    setMap(map);
  }, []);

  const param = useParams();
  const role = Cookies.get("role");

  useEffect(() => {
    if (role?.includes("State")) {
      handleGetAllZones();
    }
    if (role?.includes("District")) {
      handleGetChildren(23, "subdivision");
    }
    if (role?.includes("SubDivision")) {
      handleGetPoliceStation(11);
    }
  }, []);

  useEffect(() => {
    if (map && !screenshotCaptured && !selectedPastActivity?.screenShotId) {
      setTimeout(() => {
        captureScreenshot(selectedPastActivity?.activityId);
      }, 1000);
      setScreenshotCaptured(true);
    }
  }, [map]);

  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="relative">
        <LoadingOverlay visible={isLoading} />
        {pastActivityList?.length > 0 ? (
          <div>
            <ETable>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Start Date</Table.Th>
                  <Table.Th>Start Time</Table.Th>
                  <Table.Th>Duration</Table.Th>
                  <Table.Th>Personnel</Table.Th>
                  <Table.Th>distance Traveled</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {pastActivityList?.map((item) => (
                  <Table.Tr
                    className="cursor-pointer text-blue-400"
                    onClick={() => {
                      setSelectedPastActivity(item);
                      setIsOpenDrawer(true);
                    }}
                  >
                    <Table.Td>
                      {moment.utc(item?.startedAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("DD-MM-YYYY") || ""}
                    </Table.Td>
                    <Table.Td>
                      {moment.utc(item?.startedAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("hh:mm A") || ""}
                    </Table.Td>
                    <Table.Td>{item?.duration || ""}</Table.Td>
                    <Table.Td>
                    <PersonnelItem
                    companions={item?.companionResponses}
                  />
                    </Table.Td>
                    <Table.Td>{item.distanceTravelled ?? ""}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </ETable>
            <div className="flex justify-between items-center mt-0 border-t  pt-3 pb-8 w-full">
              <div className="count text-sm text-slate-600">
                Page no: {currentPageNo}
              </div>
              <div className="flex gap-2">
                <EButton
                  disabled={currentPageNo <= 1 ? true : false}
                  variant="clear"
                  onClick={() => {
                    getPastActivityList({
                      paginationFilterRequest: {
                        paginationAction: "PREVIOUS_PAGE",
                        maxLimit: pageLimit,
                        id: pastActivityList[0]?.activityId,
                        sortingOrder: "DESC",
                      },
                      policePersonId: param?.id ? +param?.id : 0,
                    });
                  }}
                >
                  <EButton.Icon>
                    <ChevronLeftIcon className="w-4 h-4" strokeWidth={3} />
                  </EButton.Icon>
                </EButton>
                <EButton
                  variant="clear"
                  disabled={isDisableNextButton}
                  onClick={() => {
                    getPastActivityList({
                      paginationFilterRequest: {
                        paginationAction: "NEXT_PAGE",
                        maxLimit: pageLimit,
                        id: pastActivityList?.[pastActivityList.length - 1]
                          ?.activityId,
                        sortingOrder: "DESC",
                      },
                      policePersonId: param?.id ? +param?.id : 0,
                    });
                  }}
                >
                  <EButton.Icon>
                    <ChevronRightIcon className="w-4 h-4" strokeWidth={3} />
                  </EButton.Icon>
                </EButton>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-xl text-slate-500 font-bold mt-5">
            No Past Activity Found
          </div>
        )}
      </div>

      <Drawer
        opened={isOpenDrawer}
        position="right"
        title={"Activity Details"}
        onClose={() => {
          setIsMapViewable(false);
          setIsOpenDrawer(false);
        }}
      >
        <div className="flex flex-col gap-5">
          <div>
            <p className="text-xl font-semibold border-t pt-4">
              {moment.utc(selectedPastActivity?.startedAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("DD-MM-YYYY")}
            </p>
            <p className="text-slate-500">
              {moment.utc(selectedPastActivity?.startedAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("hh:mm A")}
            </p>
          </div>
          <div className="border rounded-md h-28 bg-slate-100 flex justify-center items-center w-full">
              <Button
                variant="light"
                onClick={() => {
                  getTraveledPath(selectedPastActivity?.activityId);
                  setScreenshotCaptured(false);
                  setIsMapViewable(true);
                }}
              >
                View on map
              </Button>
            {/* )} */}
          </div>
          <div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>Activity Type</p>
              <p className="capitalize">{selectedPastActivity?.activityType?.toLocaleLowerCase()}</p>
            </div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>End Date</p>
              <p>  {moment.utc(selectedPastActivity?.endedAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("DD-MM-YYYY")}</p>
            </div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>End Time</p>
              <p>{ moment.utc(selectedPastActivity?.endedAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("hh:mm A")}</p>
            </div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>Duration</p>
              <p>{selectedPastActivity?.duration}</p>
            </div>
          </div>

          <div className="mt-3">
            <p className="text-xl font-semibold">
              Observation / Activity Undertaken
            </p>
            <p className="text-slate-600">
              {selectedPastActivity?.summary
                ? selectedPastActivity?.summary
                : "Not provided..."}
            </p>
          </div>

          <div className="mt-3 mb-3">
            <p className="text-xl font-semibold">Companions</p>
            <CompanionDetails companionDetails={selectedPastActivity?.companionResponses}/>
          </div>
        </div>
      </Drawer>

      <Modal
        opened={isMapViewable}
        onClose={() => {
          setIsMapViewable(false);
        }}
        size={"xl"}
        padding={"0"}
        withCloseButton={false}
      >
        <div><XMarkIcon
          className="w-8 h-8 text-slate-500 absolute right-2 top-2 cursor-pointer"
          onClick={() => {
            setIsMapViewable(false);
          }}
        /> </div>

        {selectedPastActivity?.screenShotId ? (
          <div className="h-[70vh]">
                <EImage
                  mediaId={selectedPastActivity?.screenShotId}
                />
          </div>
        ) : (
          <div className="h-[70vh]" ref={ToCaptureRef}>
            {isLoadingPath ? (
              <Loader type="bars" />
            ) : (
              <>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{
                      width: "100%",
                      height: "100%",
                    }}
                    onLoad={onLoad}
                    zoom={16}
                    center={defaultCenter}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      draggable: false,
                      scaleControl: false,
                      scrollwheel: false,
                      disableDefaultUI: true,
                      clickableIcons: false,
                    }}
                  >
                    {traveledPathForPolyline?.length > 0 && (
                      <PolylineF
                        visible
                        path={traveledPathForPolyline}
                        options={{
                          strokeColor: "#0000FF",
                          strokeOpacity: 0.8,
                          strokeWeight: 6,
                        }}
                      />
                    )}
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}
