import { Skeleton } from '@mantine/core';
import React from 'react'

interface IProps {
    title: string;
    subTitle: string | number;
    rightEnd?: string | number;
    loading?: boolean;
}

export default function DistrictWiseBreakDownStatsCard(
  {
    title,
    subTitle,
    rightEnd,
    loading
  }: IProps
) {
  return (
    <div className="p-4 border border-slate-200 rounded-lg cursor-pointer">
    <div className="text-sm font-medium text-slate-500">
   {title}
    </div>
    <div className="flex flex-row justify-between items-center mt-2">
    {loading ? <Skeleton height={24} width={50}/>:
    <div className="text-xl font-bold text-neutral-900 ">
          {subTitle}
      </div>}
     { String(rightEnd) ? loading  ? <Skeleton height={20} width={50}/> : <div className="text-xl font-normal text-neutral-900">{rightEnd}</div> :<></>}
    </div>
  </div>
  )
}
