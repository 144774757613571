import { IRoute, ROUTES } from "../routes/routes";

const subMenuRecursion = (submenu: IRoute[]): IRoute[] => {
  return submenu
    .map((sub) => (sub?.submenu ? subMenuRecursion(sub?.submenu) : sub))
    .flat();
};
export const matchRoute = (path: string): IRoute => {
  const route = ROUTES.ALL_ROUTES;
  const allRoutes = route
    .map((r) => (r.submenu ? subMenuRecursion(r.submenu) : r))
    .flat(2);
  return allRoutes.find((route) => route.path === path) as IRoute;
};

export const checkRouteBasedOnRoles = (
  roles: string[] | undefined,
  routeRoles: string[]
) => {
  function findCommonRoles(roles: string[] | undefined, routeRoles: string[]) {
    return roles?.some((item) => routeRoles?.includes(item));
  }
  let isPresent = findCommonRoles(roles, routeRoles);
  return isPresent;
};
