import { useContext, useMemo, useState } from "react";
import { IRoute, ROUTES } from "../../routes/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { checkRouteBasedOnRoles } from "../../utils/routeUtility";
import { AppContext } from "../../AppContext";
import Cookies from "js-cookie";
import { useAuth } from "../../libs/data-access";
import { ILogoutPayload } from "../../utils/types";

export default function useNavbar(initialIsCollapsed: boolean) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    role,
    token,
    refreshToken,
    loggedInUserInfo,
    setToken,
    setIsLoggedIn,
    setRefreshToken,
    setLoggedInUserInfo,
    handleLogOut
  } = useContext(AppContext);

  const { postAuthData } = useAuth();

  const [activeRoute, setActiveRoute] = useState<IRoute>({} as IRoute);
  const [logOutModalOpened, setLogOutModalOpened] = useState<boolean>(false);
  const [subNavActiveIndex, setSubNavActiveIndex] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    initialIsCollapsed || false
  );

  const toggleCollapse = () => {
    setIsCollapsed((prevIsCollapsed) => !prevIsCollapsed);
  };

  const subMenuRecursion = (submenu: IRoute[]): IRoute[] => {
    return submenu
      .map((sub) => (sub?.submenu ? subMenuRecursion(sub?.submenu) : sub))
      .flat();
  };

  const filterRoutesByRoles = (
    routes: IRoute[],
    roleToFilter: string[] | undefined
  ) => {
    const r: IRoute[] = routes
      ?.filter((each) => each.icon)
      ?.filter((route: IRoute) => {
        if (
          // Array.isArray(route.rolesAllowed)
          // &&
          checkRouteBasedOnRoles(roleToFilter, route?.rolesAllowed as string[])
        ) {
          if (route.submenu && route.submenu.length > 0) {
            route.submenu = filterRoutesByRoles(route.submenu, roleToFilter);
          }
          return true;
        } else if (route.submenu && route.submenu.length > 0) {
          route.submenu = filterRoutesByRoles(route.submenu, roleToFilter);
          return route.submenu && route.submenu.length > 0;
        }
        return false;
      });

    return r;
  };

  function getRouteByIndex(routes: IRoute[], indices: number[]) {
    let currentRoutes = routes;
    for (let i = 0; i < indices.length; i++) {
      const index = indices[i];
      if (
        currentRoutes &&
        Array.isArray(currentRoutes) &&
        currentRoutes[index]
      ) {
        currentRoutes = currentRoutes[index].submenu as IRoute[];
      } else {
        return []; // Invalid index or route does not exist
      }
    }
    return currentRoutes;
  }

  const activeRouteArray = useMemo(() => {
    const allRoutes = filterRoutesByRoles(ROUTES.ALL_ROUTES, role);

    if (subNavActiveIndex?.length) {
      let nestedRoute: IRoute[] | undefined = allRoutes;

      nestedRoute = getRouteByIndex(nestedRoute, subNavActiveIndex)?.filter(
        (each) => each.icon
      );

      if (nestedRoute?.[0]?.path) {
        navigate(nestedRoute[0].path);
      }

      // setCurrentRoutes(nestedRoute)
      return nestedRoute;
    } else {
      navigate("/");
      return allRoutes;
    }
  }, [role, subNavActiveIndex]);

  const handleLogout = async () => {
    setIsLoading(true);
    let payload: ILogoutPayload = {
      accessToken: token as string,
      refreshToken: refreshToken as string,
    };
    let res = await postAuthData("logout", payload);
    if (res?.isSuccess) {
      handleLogOut()
    } else {
    }
    setIsLoading(false);
  };
  return {
    role,
    isLoading,
    activeRoute,
    isCollapsed,
    activeRouteArray,
    logOutModalOpened,
    loggedInUserInfo,
    subNavActiveIndex,
    toggleCollapse,
    setActiveRoute,
    setIsCollapsed,
    setLogOutModalOpened,
    setSubNavActiveIndex,
    handleLogout,
  };
}
