import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Loader,
  LoadingOverlay,
  Modal,
  Table,
  Tooltip,
} from "@mantine/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { EButton, EPageHeader, ESelect, ETable } from "../../components";
import {
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import usePastActivity from "./hooks/usePastActivity";
import Cookies from "js-cookie";
import { GoogleMap, PolylineF, useLoadScript } from "@react-google-maps/api";
import EPersonalProfileLoader from "../../components/avatar/EPersonalProfileLoader";
import EImage from "../../components/image/EImage";
import PersonnelItem from "./components/PersonnelItem";
import CompanionDetails from "./components/CompanionDetails";
import moment from "moment";

const token = Cookies.get("token");

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const GEOCODE_API_KEY = process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY;
const GOOGLE_MAPS_LIBRARIES = ["geometry" as const];

export default function PastActivity() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY || "",
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const {
    isLoading,
    pageLimit,
    activityType,
    ToCaptureRef,
    isOpenDrawer,
    defaultCenter,
    isLoadingPath,
    isMapViewable,
    currentPageNo,
    liveStatsPayload,
    pastActivityList,
    isDisableNextButton,
    selectedPastActivity,
    activityTypeForDrawer,
    allZoneListForSelection,
    traveledPathForPolyline,
    allRangeListForSelection,
    selectedZonesAndChildren,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    setActivityType,
    getTraveledPath,
    setIsOpenDrawer,
    setIsMapViewable,
    captureScreenshot,
    handleGetAllZones,
    handleGetChildren,
    getPastActivityList,
    handleGetPoliceStation,
    setSelectedPastActivity,
    handleGetOtherFilterSelectionData,
  } = usePastActivity();

  const role = Cookies.get("role");
  const [map, setMap] = useState(null);
  const onLoad = useCallback((map: any) => {
    setMap(map);
  }, []);
  const [screenshotCaptured, setScreenshotCaptured] = useState(false);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      traveledPathForPolyline?.map((marker) => {
        bounds.extend({
          lat: marker?.lat,
          lng: marker?.lng,
        });
      });
      // @ts-ignore
      map.fitBounds(bounds);
    }
  }, [map, traveledPathForPolyline]);

  useEffect(() => {
    if (role?.includes("State")) {
      handleGetAllZones();
    }
    if (role?.includes("District")) {
      handleGetChildren(23, "subdivision");
    }
    if (role?.includes("SubDivision")) {
      handleGetPoliceStation(11);
    }
  }, []);

  useEffect(() => {
    if (map && !screenshotCaptured && !selectedPastActivity?.screenShotId) {
      setTimeout(() => {
        captureScreenshot(selectedPastActivity?.activityId);
      }, 1000);
      setScreenshotCaptured(true);
    }
  }, [map, screenshotCaptured]);

  return (
    <div className="flex flex-col gap-4">
      <EPageHeader>
        <EPageHeader.Title>Past Activity</EPageHeader.Title>
        <EPageHeader.Right>
          <div className="w-full flex flex-row gap-8 items-end justify-between">
            <div className="flex flex-row gap-1">
              <ESelect
                marginTop={false}
                data={[
                  { label: "Patrol", value: "PATROL" },
                  { label: "Nakka", value: "NAKKA" },
                ]}
                placeholder="Activity Type"
                value={activityType ?? ""}
                onChange={(e) => {
                  setActivityType(e);
                }}
              />
              {role?.includes("State") && (
                <ESelect
                  marginTop={false}
                  data={allZoneListForSelection}
                  placeholder="All Zones"
                  value={
                    selectedZonesAndChildren.zones
                      ? String(selectedZonesAndChildren.zones)
                      : null
                  }
                  onChange={(e) => {
                    handleGetOtherFilterSelectionData("range", e);
                  }}
                />
              )}
              {role?.includes("State") && (
                <ESelect
                  marginTop={false}
                  data={allRangeListForSelection}
                  value={
                    selectedZonesAndChildren.ranges
                      ? String(selectedZonesAndChildren.ranges)
                      : null
                  }
                  disabled={selectedZonesAndChildren.zones ? false : true}
                  placeholder="All range"
                  onChange={(e) => {
                    handleGetOtherFilterSelectionData("district", e);
                  }}
                />
              )}
              {role?.includes("State") && (
                <ESelect
                  marginTop={false}
                  data={allDistrictsListForSelection}
                  disabled={selectedZonesAndChildren.ranges ? false : true}
                  value={
                    selectedZonesAndChildren.districts
                      ? String(selectedZonesAndChildren.districts)
                      : null
                  }
                  placeholder="All Districts"
                  onChange={(e) => {
                    handleGetOtherFilterSelectionData("subdivision", e);
                  }}
                />
              )}
              {role?.includes("State") || role?.includes("District") ? (
                <ESelect
                  marginTop={false}
                  placeholder="All Sub Divisions"
                  data={allSubdivisionsListForSelection}
                  disabled={
                    selectedZonesAndChildren.districts ||
                    role?.includes("District")
                      ? false
                      : true
                  }
                  value={
                    selectedZonesAndChildren.subDivisions
                      ? String(selectedZonesAndChildren.subDivisions)
                      : null
                  }
                  onChange={(e) => {
                    handleGetOtherFilterSelectionData("policeStation", e);
                  }}
                />
              ) : (
                <></>
              )}
              {role?.includes("State") ||
              role?.includes("District") ||
              role?.includes("SubDivision") ? (
                <ESelect
                  marginTop={false}
                  data={allPoliceStationsListForSelection}
                  disabled={
                    selectedZonesAndChildren.subDivisions ||
                    role?.includes("SubDivision")
                      ? false
                      : true
                  }
                  value={
                    selectedZonesAndChildren.policeStation
                      ? String(selectedZonesAndChildren.policeStation)
                      : null
                  }
                  placeholder="All Police Stations"
                  onChange={(e) => {
                    handleGetOtherFilterSelectionData("last", e);
                  }}
                />
              ) : (
                <></>
              )}
              {role?.includes("State") ||
              role?.includes("District") ||
              role?.includes("SubDivision") ? (
                <>
                  <EButton
                    variant="outline"
                    onClick={() => {
                      handleGetOtherFilterSelectionData("default", 1);
                    }}
                  >
                    <EButton.Icon>
                      <ArrowPathIcon className="w-4 h-4" strokeWidth={2} />
                    </EButton.Icon>
                    <EButton.Label>Reset</EButton.Label>
                  </EButton>
                  <EButton
                    variant="primary"
                    loading={isLoading}
                    disabled={false}
                    onClick={() => {
                      getPastActivityList({
                        ...liveStatsPayload,
                        paginationFilterRequest: {
                          paginationAction: "INITIAL_PAGE",
                          maxLimit: pageLimit,
                          id: null,
                          sortingOrder: "DESC",
                        },
                        activityType: activityType,
                      });
                    }}
                  >
                    <EButton.Icon>
                      <ChevronRightIcon
                        className="w-4 h-4 text-white"
                        strokeWidth={3}
                      />
                    </EButton.Icon>
                  </EButton>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </EPageHeader.Right>
      </EPageHeader>

      {/* <ScrollAreaAutosize className=" w-full h-[74vh] relative  scroll-smooth" viewportRef={targetToScrollForTableRef}> */}
      <div className="relative">
        <LoadingOverlay visible={isLoading} />
        <ETable>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Start Date, Start Time, Duration</Table.Th>
              {/* <Table.Th>Type</Table.Th> */}
              <Table.Th>Personnel</Table.Th>
              <Table.Th>District</Table.Th>
              <Table.Th>Sub-Division</Table.Th>
              <Table.Th>Station</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {pastActivityList?.map((item) => (
              <Table.Tr
                className="cursor-pointer text-blue-400"
                onClick={() => {
                  setSelectedPastActivity(item);
                  setIsOpenDrawer(true);
                }}
              >
                <Table.Td>{moment.utc(item?.startAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("DD-MM-YYYY, hh:mm A")+ ", " + item?.duration}</Table.Td>
                <Table.Td>
                  <PersonnelItem
                    companions={item?.companionDetails}
                  />
                </Table.Td>
                <Table.Td>{item?.district?.name ?? ""}</Table.Td>
                <Table.Td>{item?.subDivision?.name ?? ""}</Table.Td>
                <Table.Td>{item?.policeStation?.briefAddress ?? ""}</Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </ETable>
      </div>
      <div className="flex justify-between items-center mt-0 border-t  pt-3 pb-8 w-full">
        <div className="count text-sm text-slate-600">
          Page no: {currentPageNo}
        </div>
        <div className="flex gap-2">
          <EButton
            disabled={currentPageNo <= 1 ? true : false}
            variant="clear"
            onClick={() => {
              getPastActivityList({
                ...liveStatsPayload,
                paginationFilterRequest: {
                  paginationAction: "PREVIOUS_PAGE",
                  maxLimit: pageLimit,
                  id: pastActivityList[0]?.activityId,
                  sortingOrder: "DESC",
                },
                activityType: activityType,
              });
            }}
          >
            <EButton.Icon>
              <ChevronLeftIcon className="w-4 h-4" strokeWidth={3} />
            </EButton.Icon>
          </EButton>
          <EButton
            variant="clear"
            disabled={isDisableNextButton}
            onClick={() => {
              getPastActivityList({
                ...liveStatsPayload,
                paginationFilterRequest: {
                  paginationAction: "NEXT_PAGE",
                  maxLimit: pageLimit,
                  id: pastActivityList?.[pastActivityList.length - 1]
                    ?.activityId,
                  sortingOrder: "DESC",
                },
                activityType: activityType,
              });
            }}
          >
            <EButton.Icon>
              <ChevronRightIcon className="w-4 h-4" strokeWidth={3} />
            </EButton.Icon>
          </EButton>
        </div>
      </div>
      <Drawer
        opened={isOpenDrawer}
        position="right"
        title={"Activity Details"}
        onClose={() => {
          setIsMapViewable(false);
          setIsOpenDrawer(false);
        }}
      >
        <div className="flex flex-col gap-5">
          <div>
            <p className="text-xl font-semibold border-t pt-4">
              {/* {moment(selectedPastActivity?.dateTimeDuration).format("ddd, DD MMM")} */}
              {moment.utc(selectedPastActivity?.startAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("DD-MM-YYYY")}
            </p>
            <p className="text-slate-500">
              {/* {moment(selectedPastActivity?.dateTimeDuration).format("hh:mm A") ?? "" + " - " + selectedPastActivity?.dateTimeDuration.split(",")[2]?? ""} */}
              {moment.utc(selectedPastActivity?.startAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("hh:mm A") ?? "" + " - " + moment.utc(selectedPastActivity?.endAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("hh:mm A") ?? ""}
            </p>
          </div>
          {activityTypeForDrawer == "PATROL" ? (
            <div
              className="border rounded-md h-28 bg-slate-100 flex justify-center items-center w-full"
            >
              <Button
                variant="light"
                onClick={() => {
                  getTraveledPath(selectedPastActivity?.activityId);
                  setScreenshotCaptured(false);
                  setIsMapViewable(true);
                }}
              >
                View on map
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600 capitalize">
              <p>Activity Type</p>
              <p>{activityTypeForDrawer.toLowerCase()}</p>
            </div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>End Date</p>
              <p>  {moment.utc(selectedPastActivity?.endAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("DD-MM-YYYY")}</p>
            </div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>End Time</p>
              <p>{ moment.utc(selectedPastActivity?.endAt, "YYYY-MM-DDTHH:mm:ss.SSSSSSZ").local().format("hh:mm A")}</p>
            </div>
            <div className="flex justify-between border-b pb-1 mb-2 text-slate-600">
              <p>Duration</p>
              <p>{selectedPastActivity?.duration}</p>
            </div>
          </div>

          <div className="mt-3">
            <p className="text-xl font-semibold">
              Observation / Activity Undertaken
            </p>
            <p className="text-slate-600">
              {selectedPastActivity?.observation
                ? selectedPastActivity?.observation
                : "Not provided..."}
            </p>
          </div>

          <div className="mt-3 mb-3">
            <p className="text-xl font-semibold">Companions</p>
            <CompanionDetails companionDetails={selectedPastActivity?.companionDetails}/>
          </div>
        </div>
      </Drawer>
      
      <Modal
        opened={isMapViewable}
        onClose={() => {
          setIsMapViewable(false);
        }}
        size={"xl"}
        title=""
        radius={"lg"}
        padding={"0"}
        withCloseButton={false}
      >
        <div><XMarkIcon
          className="w-8 h-8 text-slate-500 absolute right-2 top-2 cursor-pointer"
          onClick={() => {
            setIsMapViewable(false);
          }}
        /> </div>
        {selectedPastActivity?.screenShotId ? (
          <div className="h-[70vh]">
                <EImage
                  mediaId={selectedPastActivity?.screenShotId}
                />
          </div>
        ) : (
          <div className="h-[70vh]" ref={ToCaptureRef}>
            {isLoadingPath ? (
              <Loader type="bars" />
            ) : (
              <>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{
                      width: "100%",
                      height: "100%",
                    }}
                    onLoad={onLoad}
                    zoom={16}
                    center={defaultCenter}
                    options={{
                      zoomControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      draggable: false,
                      scaleControl: false,
                      scrollwheel: false,
                      disableDefaultUI: true,
                      clickableIcons: false,
                    }}
                  >
                    {traveledPathForPolyline?.length > 0 && (
                      <PolylineF
                        visible
                        path={traveledPathForPolyline}
                        options={{
                          strokeColor: "#0000FF",
                          strokeOpacity: 0.8,
                          strokeWeight: 6,
                        }}
                      />
                    )}
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}
