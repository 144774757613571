import { useContext, useState } from "react";
import useFiltersSelectionData from "../../../hooks/useFiltersSelectionData";
import {
  ILiveStatsPayload,
  ISelectedChildrenAndZones,
} from "../../../utils/types";
import useDashboardData from "../../../libs/data-access/src/Dashboard/useDashboardData";
import {
  IActivityStats,
  IDistrictWiseStats,
  ILiveStats,
} from "../../../libs/data-access/types";
import { ApexOptions } from "apexcharts";
import { AppContext } from "../../../AppContext";
import moment from "moment";
import { showToast } from "../../../libs/data-access/utility";

export default function useDashboard() {
  const { setSosActive } = useContext(AppContext);

  const { getDashboardData, postDashboardData } = useDashboardData();

  const {
    allZoneListForSelection,
    allDistrictsListForSelection,
    allRangeListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    liveStatsPayload,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  } = useFiltersSelectionData();

  let currentMonth = moment().format("MM");
  let currentWeek = moment().format("WW");
  let currentYear = moment().format("YYYY");

  const [isLoadingLiveStats, setIsLoadingLiveStats] = useState(false);
  const [
    isLoadingChartForPatrolAndNakkaStats,
    setIsLoadingChartForPatrolAndNakkaStats,
  ] = useState(false);
  const [
    isLoadingDistrictWiseBreakDownStats,
    setIsLoadingDistrictWiseBreakDownStats,
  ] = useState(false);

  const [liveStats, setLiveStats] = useState<ILiveStats>({} as ILiveStats);

  const [activeTabActivityType, setActiveTabActivityType] = useState<
    "WEEK" | "MONTH" | "YEAR"
  >("MONTH");
  const [chartForPatrolStats, setChartForPatrolStats] = useState({
    series: [],
    options: {
      chart: {
        type: "donut" as const,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: [],
      colors: ["#5E3FBE", "#F59E0B", "#10B981", "#EF4444"],
      dataLabels: {
        enabled: false,
      },
    },
  } as { series: number[]; options: ApexOptions });

  const [chartForNakkaStats, setChartForNakkaStats] = useState({
    series: [],
    options: {
      chart: {
        type: "donut" as const,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: [],
      colors: ["#E11D48", "#3B82F6", "#EAB308"],
      dataLabels: {
        enabled: false,
      },
    },
  } as { series: number[]; options: ApexOptions });

  const [activeTabDistrictWiseBreakDown, setActiveTabDistrictWiseBreakDown] =
    useState<{
      time: "WEEK" | "MONTH" | "YEAR";
      patrolOrNakka: "PATROL" | "CHECK_POINT";
    }>({ time: "MONTH", patrolOrNakka: "PATROL" });
  const [districtWiseBreakDownStats, setDistrictWiseBreakDownStats] =
    useState<IDistrictWiseStats>({} as IDistrictWiseStats);

  const [districtWiseBreakDownGraph, setDistrictWiseBreakDownGraph] = useState<{
    chart: {
      type: string;
    };
    options: ApexOptions;
    series: { name: string; data: { x: string; y: number }[] }[];
    labels: string[];
  }>({
    chart: {
      type: "bar",
    },
    options: {
      xaxis: {
        categories: ["Rupanagar", "Rupanagar", "Rupanagar"],
      },
      colors: ["#5E3FBE"],
    },
    series: [
      {
        name: "",
        data: [],
      },
    ],
    labels: [],
  });

  const handleGetLiveStats = async () => {
    setIsLoadingLiveStats(true);
    let res = await postDashboardData("live-stats", liveStatsPayload);
    if (res.isSuccess) {
      let data: ILiveStats = res?.data || {};
      setLiveStats(data);
    } else {
      showToast({
        type: "error",
        title: "",
        message: res?.message || "Fail to get live stats",
      });
      console.log("Error", res?.error);
    }
    setIsLoadingLiveStats(false);
  };

  const handleGetActivityTypeStats = async () => {
    setIsLoadingChartForPatrolAndNakkaStats(true);
    let filterTypePayload = {
      filterType: activeTabActivityType,
      monthNumber: currentMonth,
      weekNumber: currentWeek,
      year: currentYear,
      ...liveStatsPayload,
    };

    let res = await postDashboardData(
      "patrol-checkpoint-aggregated-stats",
      filterTypePayload
    );
    if (res.isSuccess) {
      let data: IActivityStats = res?.data || {};
      setChartForPatrolStats({
        series: data?.patrolStats ? Object.values(data?.patrolStats) : [],
        options: {
          ...chartForPatrolStats.options,
          labels: data?.patrolStats ? Object.keys(data?.patrolStats || {}) : [],
        },
      });
      setChartForNakkaStats({
        series: data?.checkPointStats
          ? Object.values(data?.checkPointStats)
          : [],
        options: {
          ...chartForNakkaStats.options,
          labels: data?.checkPointStats
            ? Object.keys(data?.checkPointStats || {})
            : [],
        },
      });
    } else {
      console.log("Error", res?.error);
    }
    setIsLoadingChartForPatrolAndNakkaStats(false);
  };

  const handleGetDistrictWiseBreakDownStats = async () => {
    setIsLoadingDistrictWiseBreakDownStats(true);
    let filterTypePayload = {
      filterType: activeTabDistrictWiseBreakDown?.time,
      monthNumber: currentMonth,
      weekNumber: currentWeek,
      year: currentYear,
      statsOf: activeTabDistrictWiseBreakDown?.patrolOrNakka,
    };

    let res = await postDashboardData(
      "district-wise-breakdown",
      filterTypePayload
    );
    if (res.isSuccess) {
      let data: IDistrictWiseStats = res?.data || {};
      let districtWiseBreakDown = data ? data?.aggregatedData : {};
      if (data) {
        setDistrictWiseBreakDownStats(data);
        if (districtWiseBreakDown) {
          let keys = districtWiseBreakDown
            ? Object.keys(districtWiseBreakDown || {})
            : [];
          let seriesData: {
            x: string;
            y: number;
          }[] = [];
          keys?.map((key, index) => {
            if (districtWiseBreakDown[key] > 0) {
              seriesData.push({
                x: key,
                y: districtWiseBreakDown[key],
              });
            }
          });

          setDistrictWiseBreakDownGraph({
            ...districtWiseBreakDownGraph,
            series: [
              {
                name: "District",
                data: seriesData,
              },
            ],
            labels: keys || [],
          });
        }
      }
    } else {
      console.log("Error", res?.error);
    }
    setIsLoadingDistrictWiseBreakDownStats(false);
  };

  const handleCheckSosStatus = async () => {
    let res = await getDashboardData("sos-status");
    if (res.isSuccess) {
      setSosActive(res?.data + "");
    } else {
      console.log("Error", res?.error);
    }
  };

  return {
    liveStats,
    isLoadingLiveStats,
    chartForNakkaStats,
    chartForPatrolStats,
    activeTabActivityType,
    allZoneListForSelection,
    selectedZonesAndChildren,
    allRangeListForSelection,
    districtWiseBreakDownStats,
    districtWiseBreakDownGraph,
    allDistrictsListForSelection,
    activeTabDistrictWiseBreakDown,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    isLoadingDistrictWiseBreakDownStats,
    isLoadingChartForPatrolAndNakkaStats,
    handleGetChildren,
    handleGetAllZones,
    handleGetLiveStats,
    handleCheckSosStatus,
    handleGetPoliceStation,
    setActiveTabActivityType,
    handleGetActivityTypeStats,
    setActiveTabDistrictWiseBreakDown,
    handleGetOtherFilterSelectionData,
    handleGetDistrictWiseBreakDownStats,
  };
}
