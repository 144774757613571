import { ArrowLeftIcon, ArrowPathIcon, ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import {
  EPageHeader,
  EButton,
  EAvatar,
  ESectionDivider,
  EStat,
} from "../../../components";
import FormInput from "./components/FormInput";
import useUserManagementProfile from "./hook/useUserManagementProfile";
import UserProfilePatrolAndNakka from "../pages/UserProfilePatrolAndNakka/UserProfilePatrolAndNakka";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import useTransfer from "./hook/useTransfer";
export default function Profile() {
  const {
    isLoading,
    activeTab,
    setActiveTab,
    userData,
  } = useUserManagementProfile();

  const {
    handleInitiateTransfer
  } = useTransfer();

  const navigate = useNavigate();

  return (
    <div className=" flex flex-col gap-4">
      <div className="w-full flex flex-row gap-2 justify-between items-center border-b border-slate-200 pb-4">
      <div className="flex flex-row gap-2 items-center">
        <ArrowLeftIcon className="w-6 h-6 mr-4 cursor-pointer" 
          onClick={() => {
            navigate(-1)
          }}
        />
        <EAvatar name={userData?.firstName + " " + userData?.lastName}/>
        <div className="text-3xl font-bold">{userData?.firstName + " " + userData?.lastName}</div>
          </div>
        {/* <EButton
            onClick={() => {
              handleInitiateTransfer();
            }}
          >
            <EButton.Icon>
              <ArrowPathIcon className="w-4 h-4" />
            </EButton.Icon>
            Initiate Transfer
          </EButton> */}
      </div>

      {/* Tabs Head */}

      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-2">
          <div className={`px-4 py-3 w-full text-sm font-medium cursor-pointer ${activeTab == "profile" ? `text-white bg-[#181950] rounded-md` : `text-blue-700`}`}
            onClick={() => setActiveTab("profile")}
          >
            Profile
          </div>
          <div className={`px-4 py-3 w-full text-sm font-medium cursor-pointer ${activeTab == "patrol" ? `text-white bg-[#181950] rounded-md` : `text-blue-700`}`}
            onClick={() => setActiveTab("patrol")}
          >
            Patrol / Nakka History
          </div>
        </div>

        {/* Tabs Body */}{
          activeTab == "profile" ?
            <>
              <div className="col-span-7">
                <ESectionDivider
                  title="Profile"
                  subTitle="User details and profile"
                  noBorder
                />

                <div className="grid grid-cols-12 gap-4 my-6">
                  <div className="col-span-6">
                    <FormInput
                      label={"First Name"}
                      value={userData?.firstName || "-"}
                    />
                    <FormInput
                      label={"Last Name"}
                      value={userData?.lastName || "-"}
                    />
                    <FormInput
                      label={"Date of Birth"}
                      value={userData?.dateOfBirth || "-"}
                    />
                    <FormInput
                      label={"Father Name"}
                      value={userData?.fatherName || "-"}
                    />
                  </div>
                  <div className="col-span-6">
                    <FormInput
                      label={"Posting Station & Sub-Station"}
                      value={userData?.policeStationName || "-"}
                    />
                    <FormInput
                      label={"Posting Date"}
                      value={"-"}
                    />
                    <FormInput
                      label={"Station Address"}
                      value={"-"}
                    />
                    <FormInput
                      label={"Station Head"}
                      value={`${userData?.stationHeadFirstName || "-"}  ${userData?.stationHeadMiddleName || ""} ${userData?.stationHeadLastName || ""}`}
                    />
                  </div>
                </div>
                <ESectionDivider
                  title="Contact Information"
                  titleStyle="text-lg font-semibold"
                  noBorder
                />
                <div className="grid grid-cols-12 gap-4 my-6">
                  <div className="col-span-6">
                    <FormInput
                      label={"Address"}
                      value={userData?.address || "-"}
                    />
                    <FormInput
                      label={"City"}
                      value={userData?.city || "-"}
                    />
                    <FormInput
                      label={"State"}
                      value={userData?.state || "-"}
                    />
                  </div>
                  <div className="col-span-6">
                    <FormInput
                      label={"Pincode"}
                      value={userData?.pinCode || "-"}
                    />
                    <FormInput
                      label={"Phone Number"}
                      value={userData?.phoneNumber || "-"}
                    />
                    <FormInput
                      label={"Email Address"}
                      value={userData?.email || "-"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-3 flex flex-col gap-4">
                <EStat>
                  <EStat.Label>Patrols</EStat.Label>
                  <EStat.Stat>{userData?.patrolCount}</EStat.Stat>
                </EStat>
                <EStat color="amber">
                  <EStat.Label>Nakkas</EStat.Label>
                  <EStat.Stat>{userData?.nakkaCount}</EStat.Stat>
                </EStat>
                <EStat color="indigo">
                  <EStat.Label>Distance Covered (km)</EStat.Label>
                  <EStat.Stat>{(Number(userData?.distanceCovered)/ 1000)?.toFixed(2)}</EStat.Stat>
                </EStat>
                <EStat color="green">
                  <EStat.Label>Time Spent</EStat.Label>
                  <EStat.Stat>{userData?.timeSpent}</EStat.Stat>
                </EStat>
              </div>
            </>
            :
            activeTab == "patrol" ?
              <div className="col-span-10">
                <UserProfilePatrolAndNakka />
              </div>
              :
              activeTab == "achievement" ?
                <p>Achievements</p> :
                activeTab == "transfer" ?
                  <p>Transfers</p> :
                  <></>
        }
      </div>
    </div>
  );
}
