import React, { useEffect, useState } from "react";
import { LoadingOverlay, MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { AppContext } from "./AppContext";
import { EAvatar, ENavbar } from "./components";
import avatarImg from "./assets/images/logo.png";
import RootRoutes from "./routes/RootRoutes";
import useApp from "./hooks/useApp";
import axios from "axios";
import Cookies from "js-cookie";
import { Notifications } from "@mantine/notifications";
import useFirebase from "./utils/useFirebase";
import SosList from "./components/soslist/SosList";
import { useLocation } from "react-router-dom";


const theme = createTheme({
  /** Put your mantine theme override here */
});

function App() {

  const location = useLocation();
  const { handleFirebaseNotifications } = useFirebase();

  const {
    role,
    token,
    sosActive,
    zoomLevel,
    isLoading,
    isLoggedIn,
    refreshToken,
    loggedInUserInfo,
    centerLatAndLong,
    firebaseDeviceToken,
    setRole,
    setToken,
    setZoomLevel,
    setSosActive,
    handleLogOut,
    setIsLoggedIn,
    setRefreshToken,
    setLoggedInUserInfo,
    setCenterLatAndLong,
    handleCheckIsLoggedIn,
    setFirebaseDeviceToken,
  } = useApp();

  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  const cancelTokenSource = axios.CancelToken.source();


  axios.interceptors.request.use(
    (request: any) => {
      const token = Cookies.get("token");
      if (!request.url.includes("auth")) {
        request.headers["Authorization"] = `Bearer ${token}`;
      }
      request.cancelToken = cancelTokenSource.token;

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error) => {
      console.log("Intercepter error", error);
      if (error?.response?.status === 401) {
        // cancelAllPendingRequests();
        // showToast({
        //   type:"warning",
        //   title:"Session Expired",
        //   message:"Please login again.",
        // })
        handleLogOut();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    handleCheckIsLoggedIn();
  }, []);

  React.useEffect(() => {
    setIsModalActive(false);
  }, [location]);

  useEffect(() => {
    console.log("sos active status", sosActive);
  }, [sosActive]);

  return (
    <AppContext.Provider
      value={{
        role,
        token,
        zoomLevel,
        sosActive,
        isLoggedIn,
        refreshToken,
        centerLatAndLong,
        loggedInUserInfo,
        firebaseDeviceToken,
        setRole,
        setToken,
        setZoomLevel,
        setSosActive,
        handleLogOut,
        setIsLoggedIn,
        setRefreshToken,
        setLoggedInUserInfo,
        setCenterLatAndLong,
        setFirebaseDeviceToken
      }}
    >
      <MantineProvider theme={theme}>
        <Notifications className="absolute top-8 right-4 pb-3 min-w-[20%]" />
        {isLoggedIn && token ? (
          <div className="flex flex-row h-screen overflow-y-hidden">
            <ENavbar />
            <div className="flex flex-col flex-1 overflow-hidden relative">
              <div className="h-16 bg-white border-b flex items-center justify-between px-6 top-0 right-0 left-0 sticky">
                <div className="left text-sm font-bold text-slate-700 tracking-tight">
                  Surakhya Portal{" "}
                  <span className="opacity-50">/ Punjab Police</span>
                </div>
                {sosActive === "true" ?<div 
                onClick={() => {
                  setIsModalActive(true);
                }}
                className="bg-rose-600 cursor-pointer text-white px-4 py-2 rounded-3xl font-bold text-sm relative justify-center flex flex-row">
                <div className="animate-ping absolute h-full w-full rounded-full bg-rose-400 opacity-75"></div>
                  SOS
                </div> : <></>}
                <div className="right flex flex-row items-center gap-6">
                  <div className="flex flex-row items-center gap-2">
                    <EAvatar
                      name={"John Smith"}
                      imageSrc={avatarImg}
                      size="sm"
                    />
                    <div className="flex flex-col">
                      <div className="text-neutral-900 text-sm font-semibold capitalize">
                        {loggedInUserInfo?.name?.firstName.toLowerCase() +
                          " " +
                          loggedInUserInfo?.name?.lastName.toLowerCase()}
                      </div>
                      <div className="text-slate-500 text-xs font-normal">
                        {loggedInUserInfo?.professionalEmail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" p-6 min-w-full h-full overflow-y-auto ">
                <RootRoutes />
              </div>
            </div>
          </div>
        ) : (
          <RootRoutes />
        )}
        <LoadingOverlay
          visible={isLoading}
          loaderProps={{ children: "Loading..." }}
        />
        <SosList
          isOpen={isModalActive}
          onClose={() => {
            setIsModalActive(false)
          }}
        />
      </MantineProvider>
    </AppContext.Provider>
  );
}

export default App;
