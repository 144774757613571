import React, { useState } from "react";
import { IPoliceStations, IZoneAndChildren } from "../libs/data-access/types";
import {
  ILiveStatsPayload,
  ILiveTrackingFilterPayload,
  ISelectBoxItems,
  ISelectedChildrenAndZones,
} from "../utils/types";
import { useLocationData, usePoliceStationData } from "../libs/data-access";

export default function useFiltersSelectionData() {
  const { getLocationData } = useLocationData();
  const { getPoliceStationData } = usePoliceStationData();

  const [liveStatsPayload, setLiveStatsPayload] = useState<ILiveStatsPayload>({
    rangeIDs: [],
    zoneIDs: [],
    districtIDs: [],
    subDivisionIDs: [],
    policeStationIDs: [],
  });
  const [liveTrackingPayload, setLiveTrackingPayload] =
    useState<ILiveTrackingFilterPayload>({
      zoneIDs: [],
      rangeIDs: [],
      districtIDs: [],
      subDivisionIDs: [],
      policeStationIDs: [],
      patrollingTypes: ["PATROLLING_UNIT", "TRAFFIC_POLICE", "DISTRICT", "SSP"],
      convenienceWays: ["BY_VEHICLE", "BY_FOOT"],
      checkPointTypes: ["HI_TECH", "PERMANENT", "TEMPORARY"],
    });

  const [selectedZonesAndChildren, setSelectedZonesAndChildren] =
    useState<ISelectedChildrenAndZones>({} as ISelectedChildrenAndZones);

  const [allZones, setAllZones] = React.useState<IZoneAndChildren[]>([]);
  const [allZoneListForSelection, setAllZoneListForSelection] = useState<
    ISelectBoxItems[]
  >([]);
  const [allRange, setAllRange] = useState<IZoneAndChildren[]>([]);
  const [allRangeListForSelection, setAllRangeListForSelection] = useState<
    ISelectBoxItems[]
  >([]);
  const [allDistricts, setAllDistricts] = React.useState<IZoneAndChildren[]>(
    []
  );
  const [allDistrictsListForSelection, setAllDistrictsListForSelection] =
    useState<ISelectBoxItems[]>([]);
  const [allSubdivisions, setAllSubdivisions] = useState<IZoneAndChildren[]>(
    []
  );
  const [allSubdivisionsListForSelection, setAllSubdivisionsListForSelection] =
    useState<ISelectBoxItems[]>([]);

  const [allPoliceStations, setAllPoliceStations] = useState<IPoliceStations[]>(
    []
  );
  const [
    allPoliceStationsListForSelection,
    setAllPoliceStationsListForSelection,
  ] = useState<ISelectBoxItems[]>([]);

  const handleGetOtherFilterSelectionData = (
    whichFilter: string,
    selectedId: number
  ) => {
    console.log(whichFilter, "+++", selectedId);
    switch (whichFilter) {
      case "default":
        setSelectedZonesAndChildren({
          zones: null,
          ranges: null,
          districts: null,
          subDivisions: null,
          policeStation: null,
        });
        setLiveStatsPayload({
          zoneIDs: [],
          rangeIDs: [],
          districtIDs: [],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setLiveTrackingPayload({
          ...liveTrackingPayload,
          zoneIDs: [],
          rangeIDs: [],
          districtIDs: [],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setAllRange([]);
        setAllRangeListForSelection([]);
        setAllDistricts([]);
        setAllDistrictsListForSelection([]);
        setAllSubdivisions([]);
        setAllSubdivisionsListForSelection([]);
        setAllPoliceStations([]);
        setAllPoliceStationsListForSelection([]);
        // handleGetChildren([]);
        break;
      case "range":
        setSelectedZonesAndChildren({
          zones: selectedId,
          ranges: null,
          districts: null,
          subDivisions: null,
          policeStation: null,
        });
        setLiveStatsPayload({
          zoneIDs: [Number(selectedId)],
          rangeIDs: [],
          districtIDs: [],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setLiveTrackingPayload({
          ...liveTrackingPayload,
          zoneIDs: [Number(selectedId)],
          rangeIDs: [],
          districtIDs: [],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setAllRange([]);
        setAllRangeListForSelection([]);
        setAllDistricts([]);
        setAllDistrictsListForSelection([]);
        setAllSubdivisions([]);
        setAllSubdivisionsListForSelection([]);
        setAllPoliceStations([]);
        setAllPoliceStationsListForSelection([]);
        handleGetChildren(selectedId, "range");
        break;
      case "district":
        setSelectedZonesAndChildren({
          ...selectedZonesAndChildren,
          ranges: selectedId,
          districts: null,
          subDivisions: null,
          policeStation: null,
        });
        setLiveStatsPayload({
          ...liveStatsPayload,
          rangeIDs: [Number(selectedId)],
          districtIDs: [],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setLiveTrackingPayload({
          ...liveTrackingPayload,
          rangeIDs: [Number(selectedId)],
          districtIDs: [],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setAllDistricts([]);
        setAllDistrictsListForSelection([]);
        setAllSubdivisions([]);
        setAllSubdivisionsListForSelection([]);
        setAllPoliceStations([]);
        setAllPoliceStationsListForSelection([]);
        handleGetChildren(selectedId, "district");
        break;
      case "subdivision":
        setSelectedZonesAndChildren({
          ...selectedZonesAndChildren,
          districts: selectedId,
          subDivisions: null,
          policeStation: null,
        });
        setLiveStatsPayload({
          ...liveStatsPayload,
          districtIDs: [Number(selectedId)],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setLiveTrackingPayload({
          ...liveTrackingPayload,
          districtIDs: [Number(selectedId)],
          subDivisionIDs: [],
          policeStationIDs: [],
        });
        setAllSubdivisions([]);
        setAllSubdivisionsListForSelection([]);
        setAllPoliceStations([]);
        setAllPoliceStationsListForSelection([]);
        handleGetChildren(selectedId, "subdivision");
        break;

      case "policeStation":
        setSelectedZonesAndChildren({
          ...selectedZonesAndChildren,
          subDivisions: selectedId,
          policeStation: null,
        });
        setLiveStatsPayload({
          ...liveStatsPayload,
          subDivisionIDs: [Number(selectedId)],
          policeStationIDs: [],
        });
        setLiveTrackingPayload({
          ...liveTrackingPayload,
          subDivisionIDs: [Number(selectedId)],
          policeStationIDs: [],
        });
        setAllPoliceStations([]);
        setAllPoliceStationsListForSelection([]);
        handleGetPoliceStation(selectedId);
        break;

      case "last":
        setSelectedZonesAndChildren({
          ...selectedZonesAndChildren,
          policeStation: selectedId,
        });
        setLiveStatsPayload({
          ...liveStatsPayload,
          policeStationIDs: [Number(selectedId)],
        });
        setLiveTrackingPayload({
          ...liveTrackingPayload,
          policeStationIDs: [Number(selectedId)],
        });
        break;

      default:
        break;
    }
  };

  const handleGetAllZones = async () => {
    let res = await getLocationData(`get-all-location-types?locationType=ZONE`);
    if (res.isSuccess) {
      let data: IZoneAndChildren[] = res?.data || [];
      setAllZones(data);
      const zonesForSelection: ISelectBoxItems[] =
        data.map((zone) => ({
          value: zone.id.toString(),
          label: zone.name,
        })) || [];

      setAllZoneListForSelection(zonesForSelection);
    } else {
      console.log("Error", res?.error);
    }
  };

  const handleGetChildren = async (id: number, type: string) => {
    let res = await getLocationData(
      `get-children-by-locationId?locationIds=${id}`
    );
    let data: IZoneAndChildren[] = [];
    let zonesForSelection: ISelectBoxItems[] = [];

    if (res.isSuccess) {
      switch (type) {
        case "range":
          data = res?.data || [];
          zonesForSelection =
            data.map((zone) => ({
              value: zone.id.toString(),
              label: zone.name,
            })) || [];

          setAllRangeListForSelection(zonesForSelection);
          setAllRange(res?.data);
          break;
        case "district":
          data = res?.data || [];
          zonesForSelection =
            data.map((zone) => ({
              value: zone.id.toString(),
              label: zone.name,
            })) || [];

          setAllDistrictsListForSelection(zonesForSelection);
          setAllDistricts(data);
          break;
        case "subdivision":
          data = res?.data || [];
          zonesForSelection =
            data.map((zone) => ({
              value: zone.id.toString(),
              label: zone.name,
            })) || [];

          setAllSubdivisionsListForSelection(zonesForSelection);
          setAllSubdivisions(data);
          break;
        default:
          break;
      }
    }
  };

  const handleGetPoliceStation = async (locationId: number) => {
    let res = await getPoliceStationData(
      `get-all-police-station-by-location?locationId=${locationId}`
    );
    if (res.isSuccess) {
      let data: IPoliceStations[] = res?.data || [];
      let policeStations: ISelectBoxItems[] =
        data.map((zone) => ({
          value: zone?.PoliceStationId?.toString(),
          label: zone.PoliceStationName,
        })) || [];

      setAllPoliceStationsListForSelection(policeStations);
      setAllPoliceStations(data);
    } else {
      console.log("Error", res?.error);
    }
  };

  return {
    allZones,
    allZoneListForSelection,
    allDistricts,
    allDistrictsListForSelection,
    allRange,
    allRangeListForSelection,
    allSubdivisions,
    allSubdivisionsListForSelection,
    allPoliceStations,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    liveStatsPayload,
    liveTrackingPayload,
    setLiveTrackingPayload,
    setSelectedZonesAndChildren,
    setLiveStatsPayload,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  };
}
