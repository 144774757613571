import React, { useEffect, useState } from 'react'
import EPersonalProfileLoader from '../../../components/avatar/EPersonalProfileLoader'
import { ICompanionDetails } from '../../../libs/data-access/types'
import { Tooltip } from '@mantine/core';

interface IProps {
    companions: ICompanionDetails[];
}

export default function PersonnelItem({companions}: IProps) {

  const [activityInCharge, setActivityInCharge] = useState<ICompanionDetails>();

  useEffect(() => {
      if(companions){
        let inCharge = companions.find((item) => item?.activityInCharge);
        setActivityInCharge(inCharge);
      }
  }, [companions])

  return (
    <div className="flex gap-2 capitalize">
    <EPersonalProfileLoader
      avtarId={activityInCharge?.avtarId ? activityInCharge?.avtarId : 0}
      size="sm"
    />
    {`
    ${
      activityInCharge
        ? `${
          activityInCharge?.firstName
              ? activityInCharge?.firstName
              : ""
          } 
 ${
  activityInCharge?.lastName
     ?activityInCharge?.lastName
     : ""
 }` + ""
        : ""
    }`}
    <Tooltip
      transitionProps={{ duration: 200 }}
      closeDelay={200}
      label={companions?.map(
        (e) => ` ${e?.firstName} ${e?.lastName}, `
      )}
    >
      <p className="flex cursor-default -ml-2 font-medium opacity-60">
        {companions?.length > 1 ? (
          <p className="text-sm">
            , {`+${companions?.length - 1}`}
          </p>
        ) : (
          " "
        )}
      </p>
    </Tooltip>
  </div>
  )
}
