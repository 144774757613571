import React, { useEffect, useState } from "react";
import { ICompanionDetails } from "../../../libs/data-access/types";
import EPersonalProfileLoader from "../../../components/avatar/EPersonalProfileLoader";
import { Badge } from "@mantine/core";

interface IProps {
  companionDetails: ICompanionDetails[];
}

export default function CompanionDetails({ companionDetails }: IProps) {
  const [activityInCharge, setActivityInCharge] = useState<ICompanionDetails>();
  const [otherCompanions, setOtherCompanions] = useState<ICompanionDetails[]>(
    []
  );

  useEffect(() => {
    if (companionDetails) {
      let inCharge = companionDetails.find((item) => item?.activityInCharge);
      setActivityInCharge(inCharge);
      let other = companionDetails
        .filter((item) => !item?.activityInCharge)
        .sort((a, b) => a?.firstName.localeCompare(b?.firstName));
      setOtherCompanions(other);
    }
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-between capitalize text-lg mt-3">
        <div className="flex flex-row gap-2">
          <EPersonalProfileLoader
            avtarId={activityInCharge?.avtarId ? activityInCharge?.avtarId : 0}
            size="md"
          />
          <span>
            {`${activityInCharge?.firstName ? activityInCharge?.firstName : ""} 
                      ${
                        activityInCharge?.lastName
                          ? activityInCharge?.lastName
                          : ""
                      }`}
          </span>
        </div>
        <Badge color="teal">In-Charge</Badge>
      </div>

      {otherCompanions?.map((item, index) => (
        <div className="flex flex-row justify-between capitalize text-lg mt-3">
          <div className="flex flex-row gap-2">
            <EPersonalProfileLoader
              avtarId={item?.avtarId ? item?.avtarId : ""}
              size="md"
            />
            <span>
              {`${item?.firstName ? item?.firstName : ""} 
                      ${item?.lastName ? item?.lastName : ""}`}
            </span>
          </div>
          {item?.activityInCharge ? (
            <Badge color="teal">In-Charge</Badge>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
}
