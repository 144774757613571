import React from "react";
import { TextInput } from "@mantine/core";

interface EInputProps {
  label?: string;
  placeholder?: string;
  description?: string;
  error?: string;
  marginTop?: boolean;
  icon?: JSX.Element;
  value?: string;
  onChange?: (value: string | number | boolean) => void;
  maxLength?: number,
  type?: string,
}

const EInput: React.FC<EInputProps> = ({
  label,
  placeholder,
  description,
  error,
  icon,
  value,
  marginTop = true,
  onChange,
  maxLength,
  type
}) => {
  return (
    <TextInput
    type={type}
      mt={marginTop ? "sm" : 0}
      size="sm"
      label={label && <div className={`font-semibold mb-1`}>{label}</div>}
      placeholder={placeholder}
      description={description}
      error={error}
      inputWrapperOrder={["label", "error", "input", "description"]}
      rightSection={icon}
      value={value}
      onChange={(e) => onChange && onChange(e.target.value)}
      maxLength={maxLength}
      
    />
  );
};

export default EInput;
