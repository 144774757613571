import { Checkbox } from "@mantine/core";
import React from "react";

interface ECheckBoxProps {
  label?: string;
  checked?: boolean;
  onChange?: () => void;
  iconEnd?: JSX.Element;
  size?: "xs" | "sm" | "md" | "lg";
}

export default function ECheckBox({
  size,
  label,
  checked,
  iconEnd,
  onChange,
}: ECheckBoxProps) {
  return (
    <div className="flex flex-row justify-between">
      <Checkbox
        className="mb-2"
        size={size || "md"}
        label={label && <div className={`font-semibold mb-1`}>{label}</div>}
        checked={checked}
        onChange={onChange}
        color="#0047FF"
      />
      {iconEnd}
    </div>
  );
}
