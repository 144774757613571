import axios from 'axios';
import React from 'react'
import { API_URL } from '../../constant';
import { res } from '../../types';
import { handleFormatResponse } from '../../utility';

export default function useDashboardData() {
        let r = res;
      
        const postDashboardData = async (endPoint: string, payload: any) => {
          try {
            let response = await axios.post(
              `${API_URL}dashboard/${endPoint}`,
              payload
            );
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;
          } catch (error: any) {
            return (r = {
              isSuccess: false,
              data: undefined,
              error: error?.response?.data,
              message: error?.response?.data?.detail || "Request Failed",
            });
          }
        };
      
        const getDashboardData = async (endPoint: string) => {
          try {
            let response = await axios.get(`${API_URL}dashboard/${endPoint}`);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;
          } catch (error: any) {
            return (r = {
              isSuccess: false,
              data: undefined,
              error: error?.response?.data,
              message: error?.response?.data?.detail || "Request Failed",
            });
          }
        };
      
        return {
          postDashboardData,
          getDashboardData,
        };
      }
      