import React from "react";

interface ESpacerProps {
  hasBorder?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
}

const ESpacer: React.FC<ESpacerProps> = ({ hasBorder, size = "sm" }) => {
  const spacerSize = {
    sm: 4,
    md: 8,
    lg: 16,
    xl: 32,
    "2xl": 64,
  };

  return (
    <div
      className={`h-[1px] my-${spacerSize[size]} ${
        hasBorder ? "bg-slate-200 " : ""
      }`}
    ></div>
  );
};

export default ESpacer;
