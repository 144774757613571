import React, { useContext, useEffect, useRef, useState } from "react";
import useDashboardData from "../../../libs/data-access/src/Dashboard/useDashboardData";
import {
  ICheckPointDetails,
  ILiveStats,
  ILiveStatsExtraDetails,
  ILiveTrackingData,
  IPatrollingDetails,
} from "../../../libs/data-access/types";
import {
  ICheckboxSection,
  IDefaultCenter,
  ILiveStatsPayload,
  IMarkerPositions,
  ISelectedChildrenAndZones,
} from "../../../utils/types";
import { showToast } from "../../../libs/data-access/utility";
import { useLoadScript } from "@react-google-maps/api";
import useFiltersSelectionData from "../../../hooks/useFiltersSelectionData";
import { AppContext } from "../../../AppContext";
import { useParams, useSearchParams } from "react-router-dom";

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const GEOCODE_API_KEY = process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY;
const GOOGLE_MAPS_LIBRARIES = ["geometry" as const];

const FOOT_PATROL_ICON =
  require("../../../assets/images/icon-foot-2.svg").default;
const VEHICLE_ICON = require("../../../assets/images/icon-vehicle.svg").default;
export default function useLiveTracking() {
  const [searchParams] = useSearchParams();
  const persist = searchParams.get("persist");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY || "",
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const { centerLatAndLong, setCenterLatAndLong } = useContext(AppContext);

  const { postDashboardData, getDashboardData } = useDashboardData();

  const {
    allZoneListForSelection,
    allDistrictsListForSelection,
    allRangeListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    liveStatsPayload,
    liveTrackingPayload,
    selectedZonesAndChildren,
    setLiveTrackingPayload,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  } = useFiltersSelectionData();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLiveStats, setIsLoadingLiveStats] = useState(false);

  const [liveStats, setLiveStats] = useState<ILiveStats>({} as ILiveStats);

  const [liveTrackingData, setLiveTrackingData] = useState<ILiveTrackingData[]>(
    []
  );

  const [markerPositions, setMarkerPositions] = useState<IMarkerPositions[]>(
    []
  );
  const [patrollingDetails, setPatrollingDetails] =
    useState<IPatrollingDetails>();
  const [checkPointDetails, setCheckPointDetails] =
    useState<ICheckPointDetails>();
  const [currentAddress, setCurrentAddress] = useState("");

  const [checkboxSections, setCheckboxSections] = useState<ICheckboxSection[]>([
    {
      sectionTitle: "",
      items: [{ label: "Show all", iconColor: "#fff", checked: true }],
    },
    {
      sectionTitle: "Patrol",
      items: [
        {
          label: "Patrolling Units",
          iconColor: "#A3E635",
          checked: true,
          value: "PATROLLING_UNIT",
        },
        {
          label: "Traffic Police",
          iconColor: "#0037C7",
          checked: true,
          value: "TRAFFIC_POLICE",
        },
        {
          label: "District",
          iconColor: "#F59E0B",
          checked: true,
          value: "DISTRICT",
        },
        { label: "SSP", iconColor: "#22C55E", checked: true, value: "SSP" },
      ],
    },
    {
      sectionTitle: "Nakka",
      items: [
        {
          label: "Hi-Tech",
          iconColor: "#64748B",
          checked: true,
          value: "HI_TECH",
        },
        {
          label: "Permanent",
          iconColor: "#DB2777",
          checked: true,
          value: "PERMANENT",
        },
        {
          label: "Temporary",
          iconColor: "#9333EA",
          checked: true,
          value: "TEMPORARY",
        },
      ],
    },
    {
      sectionTitle: "Patrol Type",
      items: [
        {
          label: "Vehicle",
          iconColor: "#DC2626",
          checked: true,
          value: "BY_VEHICLE",
        },
        {
          label: "Foot Patrol",
          iconColor: "#DC2626",
          checked: true,
          value: "BY_FOOT",
        },
      ],
    },
  ]);

  const handleGetLiveTracking = async (filter: ILiveStatsPayload) => {
    let res = await postDashboardData("live-activity-basic-details", filter);
    if (res.isSuccess) {
      let data: ILiveTrackingData[] = res?.data || [];

      if (data) {
        data = data?.filter((item) => item?.lat && item?.lon);
        data.forEach((item) => {
          item.iconPath =
            item.activityType === "FOOT_PATROL"
              ? FOOT_PATROL_ICON + `?id=${item.id}`
              : VEHICLE_ICON + `?id=${item.id}`;
        });

        setLiveTrackingData(data);
        data?.forEach((item, index) => {
          mapUpdate(
            item.lat,
            item.lon,
            item.heading,
            item?.id,
            index,
            item?.activityType
          );
        });
      }
    } else {
      console.log("Error", res?.error);
    }
  };
  let cI = 0;

  const mapUpdate = (
    lat: number,
    lng: number,
    heading: number,
    id: number,
    index: number,
    type: string
  ) => {
    if (
      window.google &&
      window.google.maps &&
      window.google.maps.geometry &&
      window.google.maps.geometry.spherical
    ) {
      const point1LatLng = new window.google.maps.LatLng(lat, lng);
      const point2LatLng = new window.google.maps.LatLng(lat, lng);

      const angle = window.google.maps.geometry.spherical.computeHeading(
        point1LatLng,
        point2LatLng
      );
      const actualAngle = heading;

      const marker = document.querySelector(
        `[src="${
          type === "FOOT_PATROL" ? FOOT_PATROL_ICON : VEHICLE_ICON
        }?id=${id}"]`
      );
      if (marker) {
        // @ts-ignore
        marker.style.transform = `rotate(${heading}deg)`;
        // @ts-ignore
        marker.style.transition = "transform 2s ease-in-out";
        // markerRef.current = marker;
      }
    } else {
      console.error("Google Maps geometry library not available");
    }
    setCenterLatAndLong(undefined);
  };

  const handleGetPatrollingDetails = async (patrollingId: number) => {
    setIsLoading(true);
    let res = await getDashboardData(
      "live-patrol-full-detail?ID=" + patrollingId
    );
    if (res?.data) {
      setPatrollingDetails(res?.data);
    } else {
      showToast({
        type: "error",
        title: "",
        message: res?.message || "Fail to get patrolling details",
      });
    }
    setIsLoading(false);
  };

  const handleGetCheckPointDetails = async (patrollingId: number) => {
    setIsLoading(true);
    let res = await getDashboardData(
      "live-check-point-full-detail?ID=" + patrollingId
    );
    if (res?.data) {
      setCheckPointDetails(res?.data);
    } else {
      showToast({
        type: "error",
        title: "",
        message: res?.message || "Fail to get patrolling details",
      });
    }
    setIsLoading(false);
  };

  const handleGetAddressFromLatLng = async (lat: number, lng: number) => {
    console.log("lat", lat, "lng", lng);
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GEOCODE_API_KEY}`
      );
      const data = await response.json();
      console.log("data", data);
      if (data.status === "OK") {
        setCurrentAddress(data.results[0].formatted_address);
      } else {
        setCurrentAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleGetLiveStats = async (
    filter: ILiveStatsPayload,
    isFirst?: boolean
  ) => {
    setIsLoadingLiveStats(true);
    let res = await postDashboardData("live-stats", filter);
    if (res.isSuccess) {
      let data: ILiveStats = res?.data || {};
      let extraDetails: ILiveStatsExtraDetails = res?.extraDetails || {};
      let defaultCenter: IDefaultCenter = {
        lat: extraDetails?.latitude,
        lng: extraDetails?.longitude,
      };
      if (isFirst && !persist) {
        setCenterLatAndLong(defaultCenter);
      } else {
        setCenterLatAndLong(undefined);
      }
      setLiveStats(data);
    } else {
      showToast({
        type: "error",
        title: "",
        message: res?.message || "Fail to get live stats",
      });
      console.log("Error", res?.error);
    }
    setIsLoadingLiveStats(false);
  };

  const handleCheckboxChange = (
    sectionIndex: number,
    itemIndex: number,
    clear?: boolean
  ) => {
    const updatedCheckboxSections = [...checkboxSections];

    if (sectionIndex === 0) {
      if (updatedCheckboxSections[0]?.items[0]?.checked || clear) {
        updatedCheckboxSections?.forEach((section) => {
          section?.items?.forEach((item) => {
            item.checked = false;
          });
        });
      } else {
        updatedCheckboxSections?.forEach((section) => {
          section?.items?.forEach((item) => {
            item.checked = true;
          });
        });
      }
    } else {
      updatedCheckboxSections[sectionIndex].items[itemIndex].checked =
        !updatedCheckboxSections[sectionIndex].items[itemIndex].checked;
    }

    let showAll = true;

    if (sectionIndex !== 0) {
      for (let i = 1; i < updatedCheckboxSections.length; i++) {
        for (let j = 0; j < updatedCheckboxSections[i]?.items?.length; j++) {
          if (!updatedCheckboxSections[i]?.items[j]?.checked) {
            showAll = false;
            break;
          }
        }
        if (!showAll) {
          break;
        }
      }
      if (showAll) {
        updatedCheckboxSections[0].items[0].checked = true;
      } else {
        updatedCheckboxSections[0].items[0].checked = false;
      }
    }
    setCheckboxSections(updatedCheckboxSections);
  };

  return {
    handleGetPatrollingDetails,
    handleGetAddressFromLatLng,
    handleGetCheckPointDetails,
    handleGetLiveTracking,
    setLiveTrackingPayload,
    patrollingDetails,
    checkPointDetails,
    centerLatAndLong,
    liveTrackingData,
    checkboxSections,
    markerPositions,
    currentAddress,
    isLoading,
    isLoaded,

    liveStats,
    liveStatsPayload,
    isLoadingLiveStats,
    liveTrackingPayload,
    allZoneListForSelection,
    selectedZonesAndChildren,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    handleCheckboxChange,
    handleGetAllZones,
    handleGetChildren,
    handleGetLiveStats,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  };
}
