import { Avatar } from "@mantine/core";
import React from "react";

interface EAvatarProps {
  name: string;
  imageSrc?: string;
  size?: "sm" | "md" | "lg";
}

const EAvatar: React.FC<EAvatarProps> = ({ name, imageSrc, size = "md" }) => {
  const getInitials = (name: string) => {
    const names = name.split(" ");
    return names
      .map((n) => n.charAt(0))
      .join("")
      .toUpperCase();
  };

  const initials = getInitials(name);
  return (
    <div
      className={`h-8 w-8 bg-slate-200 rounded-full inline-flex items-center justify-center overflow-hidden flex-shrink-0`}
    >
      {imageSrc ? (
        // <img src={imageSrc} alt={name} className="" />
        <Avatar src={imageSrc} size={size} />
      ) : (
        <span className="font-bold uppercase text-white">{initials}</span>
      )}
    </div>
  );
};

export default EAvatar;
