import { AxiosResponse } from "axios";

import { IResponse, res } from "./types";

import { notifications } from "@mantine/notifications";
/*
 * format return type for API success (Status Code: 200) response
 */
export const handleFormatResponse = (response: AxiosResponse<any, any>) => {
  const data: IResponse<any> = response.data ?? res;
  let r = res;
  // console.log("Response in handleformat: ", response);
  return (r = {
    ...r,
    isSuccess: true,
    data: data?.data,
    extraDetails: data?.extraDetails,
    message: "success",
  });
};

export const showToast = (data: {
  message: string;
  title?: string;
  type?: "error" | "success" | "warning";
  time?: number;
  icon?: JSX.Element;
  className?: string;
  isLoading?: boolean;
  nonClosable?: boolean;
}) => {
  notifications.show({
    title: data?.title,
    message: data?.message,
    color:
      data?.type === "error"
        ? "red"
        : data?.type === "success"
        ? "green"
        : data?.type === "warning"
        ? "yellow"
        : "blue",
    autoClose: data?.time ? data?.time : 4000,
    icon: data?.icon,
    className: data?.className,
    loading: data?.isLoading,
  });
};
