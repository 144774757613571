import React, { ReactNode } from "react";
import "./Card.scss";

interface ECardProps {
  children: React.ReactNode; // Use React.ReactNode for children
}

const ECard: React.FC<ECardProps> & {
  Head: React.FC<{ title: string; children: ReactNode }>;
  Body: React.FC<{ children: ReactNode }>;
  Foot: React.FC<{ children: ReactNode }>;
} = ({ children }) => {
  return <div className={`card`}>{children}</div>;
};

// Define Card.Head
ECard.Head = ({ title, children }) => (
  <div className="card--head">
    <h2 className="font-bold">{title}</h2>
    <div>{children}</div>
  </div>
);

// Define Card.Body
ECard.Body = ({ children }) => <div className="card--body">{children}</div>;
// Define Card.Foot
ECard.Foot = ({ children }) => <div className="card--foot">{children}</div>;

export default ECard;
