import React, { useEffect } from 'react'
import useDashboard from '../hooks/useDashboard'
import { EStat } from '../../../components';
import { Loader } from '@mantine/core';
import { ILiveStatsPayload } from '../../../utils/types';
import useLiveTracking from '../../tracking/hooks/useLiveTracking';
import { ILiveStats } from '../../../libs/data-access/types';
 type IProps ={
  isLoadingLiveStats : boolean;
  liveStats: ILiveStats;
 }
export default function LiveActivityStats({isLoadingLiveStats , liveStats}: IProps) {

  return (
    <div className="grid grid-cols-4 gap-4">
    <EStat>
      <EStat.Label>Active Patrols</EStat.Label>
      {/* <EStat.Stat>{isLoadingLiveStats ? <Loader size={25}/> : liveStats?.livePatrollingCount}</EStat.Stat> */}
      <EStat.Stat>{liveStats?.livePatrollingCount}</EStat.Stat>
    </EStat>
    <EStat color="amber">
      <EStat.Label>Patrolling Personnel</EStat.Label>
      {/* <EStat.Stat>{isLoadingLiveStats ? <Loader size={25}/> : liveStats?.livePatrollingPersonnelCount}</EStat.Stat> */}
      <EStat.Stat>{liveStats?.livePatrollingPersonnelCount}</EStat.Stat> 
    </EStat>
    <EStat color="indigo">
      <EStat.Label>Active Nakkas</EStat.Label>
      {/* <EStat.Stat>{isLoadingLiveStats ? <Loader size={25}/> : liveStats?.liveCheckPointCount}</EStat.Stat> */}
      <EStat.Stat>{liveStats?.liveCheckPointCount}</EStat.Stat> 
    </EStat>
    <EStat color="green">
      <EStat.Label>Nakka Personnel</EStat.Label>
      {/* <EStat.Stat>{isLoadingLiveStats ? <Loader size={25}/> : liveStats?.liveCheckPointPersonnelCount}</EStat.Stat> */}
      <EStat.Stat>{liveStats?.liveCheckPointPersonnelCount}</EStat.Stat> 
    </EStat>
  </div>
  )
}
