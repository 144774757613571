import React, { ReactNode } from "react";

interface EPageHeaderProps {
  children: React.ReactNode; // Use React.ReactNode for children
}

const EPageHeader: React.FC<EPageHeaderProps> & {
  Title: React.FC<{ children: ReactNode }>;
  Right: React.FC<{ children: ReactNode }>;
} = ({ children }) => {
  return (
    <div className={`flex flex-row items-center justify-between gap-24 pb-3`}>
      {children}
    </div>
  );
};

// Define PageHeader.Title
EPageHeader.Title = ({ children }) => (
  <h1 className="font-bold">{children}</h1>
);

// Define Right
EPageHeader.Right = ({ children }) => (
  <div className="flex flex-row gap-1 max-w-5xl">{children}</div>
);

export default EPageHeader;
