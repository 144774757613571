import React, { FC, ReactNode } from "react";
import './Segment.scss';

interface ESegmentProps {
  label: string;
  handleClick?: ()=>void;
  isActive?: boolean;
}

const ESegment: FC<ESegmentProps> & { Wrapper: FC<{ children: ReactNode }> } = ({
  label,
  isActive,
  handleClick
}) => {
  return <div className={`segment ${isActive ? "active" : ""}`} onClick={()=> handleClick &&handleClick()}>{label}</div>;
};

ESegment.Wrapper = ({ children }: { children: ReactNode }) => {
  return <div className="segment-wrapper">{children}</div>;
};

export default ESegment;
