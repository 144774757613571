import { Loader } from "@mantine/core";
import React, { ElementType, ReactNode } from "react";
import { JsxElement } from "typescript";

interface EButtonProps {
  children: React.ReactNode; // Use React.ReactNode for children
  // icon?: JsxElement;
  fullWidth?: boolean;
  variant?: "primary" | "secondary" | "light" | "outline" | "link" | "danger" | "clear";
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
}

const variantStyles = {
  primary: "bg-blue-700 text-white hover:bg-blue-800",
  secondary: "bg-black text-white hover:bg-slate-900",
  light: "bg-blue-50 text-blue-700 hover:bg-blue-100 border border-slate-200",
  outline: "bg-white text-blue-700 hover:bg-slate-50 border border-slate-200",
  link: "bg-white text-blue-700 hover:bg-slate-50 border border-slate-200",
  danger: "bg-rose-600 text-white hover:bg-rose-700",
  clear: "bg-transparent text-black hover:bg-slate-200",
};

const EButton: React.FC<EButtonProps> & {
  Label: React.FC<{ children: ReactNode }>;
  Icon: React.FC<{ children: ReactNode }>;
} = ({
  children,
  fullWidth,
  variant = "primary",
  onClick,
  disabled,
  loading,
}) => {
  const style = variantStyles[variant] || variantStyles.primary;

  return (
    <div
      className={`h-[36px] rounded  gap-2 items-center justify-center text-sm font-semibold select-none hover:shadow-inner  ${style} ${
        fullWidth ? "flex" : "inline-flex px-3"
      } ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
      onClick={() => !disabled && onClick && onClick()}
    >
      {children}

      {loading ? <Loader size={"xs"} /> : <></>}
    </div>
  );
};

// Define Button.Label
EButton.Label = ({ children }) => <span>{children}</span>;

// Define Button.Icon
EButton.Icon = ({ children }) => <div>{children}</div>;

export default EButton;
