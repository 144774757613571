import {
  ArrowPathIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import {
  EPageHeader,
  ESelect,
  EButton,
  EInput,
  ETable,
  ECheckBox,
} from "../../components";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Avatar, Loader, Pagination, Table } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useUserManagement from "./hooks/useUserManagement";
import Cookies from "js-cookie";
import EPersonalProfileLoader from "../../components/avatar/EPersonalProfileLoader";

export default function UserManagement() {
  const navigate = useNavigate();
  const role = Cookies.get("role");

  const {
    isLoading,
    userList,
    isDisableNextButton,
    pageLimit,
    paginationAndFilterPayload,
    setPaginationAndFilterPayload,
    fetchUserList,
    currentPageNo,
    liveStatsPayload,
    searchValue,
    setSearchValue,
    allZoneListForSelection,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetChildren,
    handleGetPoliceStation,
    handleGetOtherFilterSelectionData,
  } = useUserManagement();

  useEffect(() => {
    if (role?.includes("State")) {
      handleGetAllZones();
    }
    if (role?.includes("District")) {
      handleGetChildren(23, "subdivision");
    }
    if (role?.includes("SubDivision")) {
      handleGetPoliceStation(11);
    }
  }, []);

  return (
    <div className=" flex flex-col gap-4">
      <EPageHeader>
        <EPageHeader.Title>User Management</EPageHeader.Title>
        {/* <EPageHeader.Right>
          <EButton variant="primary" onClick={() => { }}>
            <EButton.Icon>
              <PlusIcon className="w-4 h-4 text-white" strokeWidth={3} />
            </EButton.Icon>
            Add New
          </EButton>
        </EPageHeader.Right> */}
      </EPageHeader>

      <div className="w-full flex flex-row gap-8 items-end justify-between">
        <EInput
          placeholder="Search by name..."
          value={searchValue ?? ""}
          onChange={(e) => setSearchValue(e ? String(e) : "")}
          icon={<MagnifyingGlassIcon className="w-4 h-4 text-gray-400" />}
        />
        <div className="flex flex-row gap-1">
          {role?.includes("State") && (
            <ESelect
              marginTop={false}
              data={allZoneListForSelection}
              placeholder="All Zones"
              value={
                selectedZonesAndChildren.zones
                  ? String(selectedZonesAndChildren.zones)
                  : null
              }
              onChange={(e) => {
                handleGetOtherFilterSelectionData("range", e);
              }}
            />
          )}
          {role?.includes("State") && (
            <ESelect
              marginTop={false}
              data={allRangeListForSelection}
              value={
                selectedZonesAndChildren.ranges
                  ? String(selectedZonesAndChildren.ranges)
                  : null
              }
              disabled={selectedZonesAndChildren.zones ? false : true}
              placeholder="All range"
              onChange={(e) => {
                handleGetOtherFilterSelectionData("district", e);
              }}
            />
          )}
          {role?.includes("State") && (
            <ESelect
              marginTop={false}
              data={allDistrictsListForSelection}
              disabled={selectedZonesAndChildren.ranges ? false : true}
              value={
                selectedZonesAndChildren.districts
                  ? String(selectedZonesAndChildren.districts)
                  : null
              }
              placeholder="All Districts"
              onChange={(e) => {
                handleGetOtherFilterSelectionData("subdivision", e);
              }}
            />
          )}
          {role?.includes("State") || role?.includes("District") ? (
            <ESelect
              marginTop={false}
              placeholder="All Sub Divisions"
              data={allSubdivisionsListForSelection}
              disabled={
                selectedZonesAndChildren.districts || role?.includes("District")
                  ? false
                  : true
              }
              value={
                selectedZonesAndChildren.subDivisions
                  ? String(selectedZonesAndChildren.subDivisions)
                  : null
              }
              onChange={(e) => {
                handleGetOtherFilterSelectionData("policeStation", e);
              }}
            />
          ) : (
            <></>
          )}
          {role?.includes("State") ||
          role?.includes("District") ||
          role?.includes("SubDivision") ? (
            <ESelect
              marginTop={false}
              data={allPoliceStationsListForSelection}
              disabled={
                selectedZonesAndChildren.subDivisions ||
                role?.includes("SubDivision")
                  ? false
                  : true
              }
              value={
                selectedZonesAndChildren.policeStation
                  ? String(selectedZonesAndChildren.policeStation)
                  : null
              }
              placeholder="All Police Stations"
              onChange={(e) => {
                handleGetOtherFilterSelectionData("last", e);
              }}
            />
          ) : (
            <></>
          )}
          {role?.includes("State") ||
          role?.includes("District") ||
          role?.includes("SubDivision") ? (
            <>
              <EButton
                    variant="outline"
                    onClick={() => {
                      handleGetOtherFilterSelectionData("default", 1);
                    }}
                  >
                    <EButton.Icon>
                      <ArrowPathIcon
                        className="w-4 h-4"
                        strokeWidth={2}
                      />
                    </EButton.Icon>
                    <EButton.Label>
                      Reset
                    </EButton.Label>
                  </EButton>
              <EButton
                variant="primary"
                loading={isLoading}
                disabled={false}
                onClick={() => {
                  fetchUserList({
                    ...liveStatsPayload,
                    paginationFilterRequest: {
                      paginationAction: "INITIAL_PAGE",
                      maxLimit: pageLimit,
                      id: null,
                      sortingOrder: "DESC",
                    },
                  });
                }}
              >
                <EButton.Icon>
                  <ChevronRightIcon
                    className="w-4 h-4 text-white"
                    strokeWidth={3}
                  />
                </EButton.Icon>
              </EButton>{" "}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ETable>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Position</Table.Th>
            <Table.Th>Phone</Table.Th>
            <Table.Th>Station</Table.Th>
            <Table.Th>No. of Patrols</Table.Th>
            {/* <Table.Th></Table.Th> */}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {isLoading ? (
            <Table.Tr>
              <Table.Td colSpan={6}>
                <div className="flex w-full p-14 justify-center items-center">
                  <Loader size={30} />
                </div>
              </Table.Td>
            </Table.Tr>
          ) : (
            userList.map((data, index) => (
              <Table.Tr
                key={index}
                className="cursor-pointer"
                onClick={() => {
                  navigate("/user-management/profile/" + data.policePersonId);
                }}
              >
                <Table.Td>
                  <div className="flex flex-row gap-2 items-center capitalize">
                    {/* <Avatar /> */}
                    <EPersonalProfileLoader avtarId={data?.mediaFileId} size="sm" />
                    {data.name?.firstName ?? ""}
                    {" " + data.name?.lastName ?? ""}
                  </div>
                </Table.Td>
                <Table.Td className="capitalize">
                  {data.position.replaceAll("_", " ").toLowerCase() ?? ""}
                </Table.Td>
                <Table.Td>{data.phoneNumber ?? ""}</Table.Td>
                <Table.Td>{data.policeStationName ?? ""}</Table.Td>
                <Table.Td>{data.noOfActivity ?? 0}</Table.Td>
              </Table.Tr>
            ))
          )}
        </Table.Tbody>
      </ETable>
      <div className="flex justify-between items-center mt-0 border-t  pt-3 pb-8 w-full">
        <div className="count text-sm text-slate-600">
          Page {currentPageNo}
        </div>
        <div className="flex gap-2">
          <EButton
            variant="clear"
            disabled={currentPageNo < 2 ? true : false}
            onClick={() =>
              fetchUserList({
                paginationFilterRequest: {
                  paginationAction: "PREVIOUS_PAGE",
                  maxLimit: pageLimit,
                  id: userList[0]?.policePersonId,
                  sortingOrder: "DESC",
                },
                nameOrPostion: null,
              })
            }
          >
            <EButton.Icon>
              <ChevronLeftIcon className="w-4 h-4" strokeWidth={2} />
            </EButton.Icon>
          </EButton>
          <EButton
            variant="clear"
            disabled={
              userList?.length < pageLimit || isDisableNextButton ? true : false
            }
            onClick={() =>
              fetchUserList({
                paginationFilterRequest: {
                  paginationAction: "NEXT_PAGE",
                  maxLimit: pageLimit,
                  id: userList?.[userList.length - 1]?.policePersonId,
                  sortingOrder: "DESC",
                },
                nameOrPostion: null,
              })
            }
          >
            <EButton.Icon>
              <ChevronRightIcon className="w-4 h-4" strokeWidth={2} />
            </EButton.Icon>
          </EButton>
        </div>
      </div>
    </div>
  );
}
