import React, { useContext, useEffect, useMemo, useState } from "react";
import "./navbar.scss";
import {
  ArrowRightStartOnRectangleIcon,
  ArrowUpTrayIcon,
  Bars2Icon,
  ChartPieIcon,
  ChevronLeftIcon,
  CogIcon,
  MapIcon,
  RectangleStackIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import Logo from "../../assets/images/logo.png";
import Spacer from "../spacer/Spacer";
import { motion } from "framer-motion";
import { ROUTES, IRoute } from "../../routes/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { checkRouteBasedOnRoles, matchRoute } from "../../utils/routeUtility";
import { AppContext } from "../../AppContext";
import useApp from "../../hooks/useApp";
import { Modal, Select } from "@mantine/core";
import EButton from "../button/EButton";
import Cookies from "js-cookie";
import useNavbar from "./useNavbar";

const NAVBAR_OPEN_SIZE = 280;
const NAVBAR_COLLAPSED_SIZE = 72;

type Props = {
  role: string[] | undefined;
};

interface NavbarProps {
  isCollapsed?: boolean;
}

interface NavItemProps {
  label: string;
  icon: JSX.Element | any;
  onClick?: () => void;
  isCollapsed?: boolean;
  isActive?: boolean;
}

const menuItems = [
  {
    label: "Dashboard",
    icon: "DashboardIcon",
    isActive: true,
  },
  {
    label: "Live Tracking",
    icon: "LiveTrackingIcon",
    isActive: false,
  },
  {
    label: "User Management",
    icon: "UsersIcon",
    isActive: false,
  },
  {
    label: "Reports & Analysis",
    icon: "ReportsIcon",
    isActive: false,
  },
  {
    label: "Settings",
    icon: "SettingsIcon",
    isActive: false,
  },
];

const iconMapping: { [key: string]: JSX.Element } = {
  DashboardIcon: <RectangleStackIcon />,
  LiveTrackingIcon: <MapIcon />,
  UsersIcon: <UsersIcon />,
  ReportsIcon: <ChartPieIcon />,
  SettingsIcon: <CogIcon />,
};

const navItemContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 10,
      delayChildren: 0.1,
      staggerChildren: 0.1,
    },
  },
};
const navbarVariants = {
  open: {
    width: NAVBAR_OPEN_SIZE,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 10,
    },
  },
  collapsed: {
    width: NAVBAR_COLLAPSED_SIZE,
    transition: {
      type: "spring",
      stiffness: 50,
      damping: 10,
    },
  },
};

function ENavbar({ isCollapsed: initialIsCollapsed }: NavbarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    role,
    isLoading,
    activeRoute,
    isCollapsed,
    loggedInUserInfo,
    activeRouteArray,
    logOutModalOpened,
    subNavActiveIndex,
    handleLogout,
    setIsCollapsed,
    toggleCollapse,
    setActiveRoute,
    setSubNavActiveIndex,
    setLogOutModalOpened,
  } = useNavbar(initialIsCollapsed || false);

  useEffect(() => {
    setActiveRoute(matchRoute(location.pathname));
  }, [location.pathname]);

  return (
    <motion.div
      animate={isCollapsed ? "collapsed" : "open"}
      variants={navbarVariants}
      className={`navbar relative ${isCollapsed ? "collapsed" : ""}`}
    >
      {/* handle to collapse or expand navbar */}
      <motion.div
        className={`absolute z-20 top-8 -mt-4 right-0 -mr-4 h-8 w-8 bg-white rounded-full border-2 border-white shadow-lg inline-flex items-center justify-center hover:cursor-pointer transition-all ${
          isCollapsed ? "rotate-90" : ""
        }`}
        onClick={toggleCollapse}
      >
        <Bars2Icon className={`text-slate-600 w-4 h-4 `} strokeWidth={3} />
      </motion.div>
      <div className="navbar--top">
        <img src={Logo} alt="" className="h-12" />
        {isCollapsed ? null : (
          <div className={`text-white `}>
            <h3 className="font-bold">Surakhya</h3>
            <p className="muted">Punjab Police</p>
          </div>
        )}
      </div>

      <div className="navbar--body">
        <motion.div
          variants={navItemContainer}
          initial="hidden"
          animate="visible"
        >
          {/* {menuItems.map((item, index) => (
            <NavItem
              key={index}
              icon={iconMapping[item.icon]}
              label={item.label}
              isActive={item.isActive}
              isCollapsed={isCollapsed}
            />
          ))} */}
          <>
            {activeRouteArray &&
              activeRouteArray.length > 0 &&
              activeRouteArray
                ?.filter((each) => each.icon !== undefined)
                .map((route: IRoute, index: number) => {
                  return (
                    <NavItem
                      key={index}
                      icon={<route.icon /> || <></>}
                      label={route.label}
                      isActive={activeRoute?.path === route.path}
                      isCollapsed={isCollapsed}
                      onClick={() => navigate(route.path)}
                    />
                  );
                })}
          </>
        </motion.div>
      </div>
      <div className="navbar--footer">
        <NavItem
          icon={<ArrowRightStartOnRectangleIcon />}
          label="Logout"
          isCollapsed={isCollapsed}
          onClick={() => setLogOutModalOpened(true)}
        />
      </div>

      <Modal
        opened={logOutModalOpened}
        onClose={() => setLogOutModalOpened(false)}
        title="Are you sure, you want to logout?"
      >
        <div className="flex justify-end gap-2 pt-8">
          <EButton
            variant="light"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              handleLogout();
              setLogOutModalOpened(false);
            }}
          >
            Yes, Logout
          </EButton>
          <EButton
          disabled={isLoading}
            variant="primary"
            onClick={() => {
              setLogOutModalOpened(false);
            }}
          >
            Cancel
          </EButton>
        </div>
      </Modal>
    </motion.div>
  );
}

export default ENavbar;

function NavItem({
  label,
  isActive,
  icon,
  onClick,
  isCollapsed,
}: NavItemProps) {
  const navItem = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  };
  const navItemCollapseVariants = {
    open: {
      opacity: 1,
      width: "auto",
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
    collapsed: {
      opacity: 0,
      width: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };
  return (
    <motion.div
      className={`nav-item ${isActive ? "active" : ""} ${
        isCollapsed ? "collapsed" : ""
      }`}
      onClick={onClick}
      variants={navItem}
    >
      {/* icon */}
      <div className="nav-item--icon">{icon}</div>
      <motion.div
        animate={isCollapsed ? "collapsed" : "open"}
        variants={navItemCollapseVariants}
        className="nav-item--label"
      >
        {label}
      </motion.div>
    </motion.div>
  );
}
