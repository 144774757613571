import React from 'react'
import ReactApexChart from 'react-apexcharts'

export default function NoDataRingChart() {
  return (
    <ReactApexChart
                  options={ {
                    chart: {
                      type: "donut" as const,
                    },
                    responsive: [
                      {
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200,
                          },
                          legend: {
                            position: "bottom",
                          },
                        },
                      },
                    ],
                    labels: ["No data found"],
                    legend: {
                      position: "right",
                      horizontalAlign: "center",
                      offsetY: 10,
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      
                    },
                    colors: ["#808080"],
                    dataLabels: {
                      enabled: false,
                    },
                  }}
                  series={[1]}
                  type="pie"
                  height={"100%"}
                  width={"100%"}
                />
  )
}
