import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/auth/login";
import { IRoute, ROUTES } from "./routes";
import { AppContext } from "../AppContext";
import { checkRouteBasedOnRoles } from "../utils/routeUtility";
import useFirebase from "../utils/useFirebase";
import useDashboard from "../pages/dashboard/hooks/useDashboard";

export default function RootRoutes() {
  const { isLoggedIn, role, token } = useContext(AppContext);

  const {
    handleCheckSosStatus
  } = useDashboard();
  const {handleFirebaseNotifications} = useFirebase()

  
  useEffect(() => {
if(isLoggedIn){
  handleCheckSosStatus()
  handleFirebaseNotifications();
}
  }, [isLoggedIn])
  

  const filterRoutesByRoles = (
    routes: IRoute[],
    roleToFilter: string[] | undefined
  ) => {
    const r: IRoute[] = routes?.filter((route: IRoute) => {
      if (
        // Array.isArray(route.rolesAllowed)
        // &&
        checkRouteBasedOnRoles(roleToFilter, route?.rolesAllowed as string[])
      ) {
        if (route.submenu && route.submenu.length > 0) {
          route.submenu = filterRoutesByRoles(route.submenu, roleToFilter);
        }
        return true;
      } else if (route.submenu && route.submenu.length > 0) {
        route.submenu = filterRoutesByRoles(route.submenu, roleToFilter);
        return route.submenu && route.submenu.length > 0;
      }
      return false;
    });

    return r;
  };

  // console.log("ROUTES.DEPARTMENT_ROUTES: ", ROUTES.DEPARTMENT_ROUTES);
  // console.log(filterRoutesByRoles(ROUTES.DEPARTMENT_ROUTES, role));

  const subMenuRecursion = (submenu: IRoute[]): JSX.Element[] => {
    return submenu.map((sub, i) => {
      return (
        <>
          <Route
            key={"sub-route" + i}
            path={sub.path}
            element={<sub.element />}
          />
          {sub.submenu && subMenuRecursion(sub?.submenu)}
        </>
      );
    });
  };

  return (
    <Routes>
      <>
        {isLoggedIn === true && token !== undefined ? (
          <>
            {
              // ROUTES.DEPARTMENT_ROUTES.map((each, index) =>
              //     checkRouteBasedOnRoles(role, each?.rolesAllowed as string[])
              filterRoutesByRoles(ROUTES.ALL_ROUTES, role).map(
                (each, index) => (
                  // ?
                  <React.Fragment key={"route" + index}>
                    <Route path={each.path} element={<each.element />} />
                    {each.submenu && subMenuRecursion(each.submenu)}
                  </React.Fragment>
                )
                // :
                // <></>
              )
            }
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
          </>
        )}
      </>
    </Routes>
  );
}
