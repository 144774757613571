import React from "react";

import axios from "axios";
import { API_URL } from "../../constant";
import { handleFormatResponse } from "../../utility";
import { res } from "../../types";
import { ILoginPayload } from "../../../../utils/types";

export function usePolicePersonnel() {
  let r = res;

  const postPolicePersonalData = async (endPoint: string, payload: any) => {
    try {
      let response = await axios.post(
        `${API_URL}police/personnel/${endPoint}`,
        payload
      );
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      return (r = {
        isSuccess: false,
        data: undefined,
        error: error?.response?.data,
        message: error?.response?.data?.detail || "Request Failed",
      });
    }
  };

  const patchPolicePersonalData = async (endPoint: string, payload: any) => {
    try {
      let response = await axios.patch(
        `${API_URL}police/personnel/${endPoint}`,
        payload
      );
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      return (r = {
        isSuccess: false,
        data: undefined,
        error: error?.response?.data,
        message: error?.response?.data?.detail || "Request Failed",
      });
    }
  };

  const getPolicePersonalData = async (endPoint: string) => {
    try {
      let response = await axios.get(`${API_URL}police/personnel/${endPoint}`);
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      return (r = {
        isSuccess: false,
        data: undefined,
        error: error?.response?.data,
        message: error?.response?.data?.detail || "Request Failed",
      });
    }
  };

  return {
    patchPolicePersonalData,
    postPolicePersonalData,
    getPolicePersonalData,
  };
}
