import { Skeleton } from "@mantine/core";
import React from "react";

export function RingChartLoader() {
  return (
    <div className="flex justify-around">
      <div className="w-1/2">
      <Skeleton circle height={160} /> 
      </div>
      <div className="w-1/6 flex flex-col pt-4 items-end">
        <Skeleton height={8} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
        <Skeleton height={8} mt={6} width="70%" radius="xl" />
        <Skeleton height={8} mt={6} radius="xl" />
      </div>
    </div>
  );
}
