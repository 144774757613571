import { createContext, Dispatch, SetStateAction } from "react";
import {
  ILiveStatsExtraDetails,
  ILoggedInUserInfo,
} from "./libs/data-access/types";
import { IDefaultCenter, ILiveStatsPayload, IRole } from "./utils/types";

export interface IAuthContext {
  role: IRole[] | undefined;
  setRole: Dispatch<SetStateAction<IRole[] | undefined>>;
  isLoggedIn: boolean | undefined;
  setIsLoggedIn: Dispatch<SetStateAction<boolean | undefined>>;
  token: string | undefined;
  setToken: Dispatch<SetStateAction<undefined | string>>;
  refreshToken: string | undefined;
  setRefreshToken: Dispatch<SetStateAction<undefined | string>>;
  loggedInUserInfo: ILoggedInUserInfo | undefined;
  setLoggedInUserInfo: Dispatch<SetStateAction<undefined | ILoggedInUserInfo>>;
  handleLogOut: () => void;
  centerLatAndLong: IDefaultCenter | undefined;
  setCenterLatAndLong: Dispatch<SetStateAction<IDefaultCenter | undefined>>;
  setFirebaseDeviceToken: Dispatch<SetStateAction<string>>;
  firebaseDeviceToken: string;
  setSosActive: Dispatch<SetStateAction<any>>;
  sosActive: any;
  setZoomLevel: Dispatch<SetStateAction<number>>;
  zoomLevel: number;
}

export const AppContext = createContext<IAuthContext>({
  role: undefined,
  setRole: () => {},
  isLoggedIn: true,
  setIsLoggedIn: () => {},
  token: undefined,
  setToken: () => {},
  refreshToken: undefined,
  setRefreshToken: () => {},
  loggedInUserInfo: undefined,
  setLoggedInUserInfo: () => {},
  handleLogOut: () => {},
  centerLatAndLong: undefined,
  setCenterLatAndLong: () => {},
  setFirebaseDeviceToken: () => {},
  firebaseDeviceToken: "",
  setSosActive: () => {},
  sosActive: false,
  setZoomLevel: () => {},
  zoomLevel: 0,
});
