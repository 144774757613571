import { ChevronRightIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
interface IProps {
  title: string;
  subTitle: string;
  hideEndIcon?: boolean
}

export default function ReportCard({ title, subTitle, hideEndIcon= false }: IProps) {
  return (
    <CSVLink
    className=""
    data={[]}
    headers={["Date"]}
    filename={`${title}_${subTitle}_${moment().format("DD-MM-YYYY")}.csv`}
>
    <div className="p-4 border border-slate-300 hover:border-slate-400 rounded-lg cursor-pointer" 
    >
      <div className="flex flex-row justify-between items-center">
        <div className="text-base font-bold text-blue-800">{title}</div>
        {hideEndIcon ? <></> : <ChevronRightIcon className="w-4 h-4 text-blue-800" />}
      </div>
      <div>
        <div className="text-sm font-normal text-slate-500 mt-2">{subTitle}</div>
      </div>
    </div>
    </CSVLink>
  );
}
