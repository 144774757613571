import { useEffect, useState, Suspense, lazy } from "react";
import {
  ArrowPathIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { EButton, EPageHeader, ESelect, ECheckBox } from "../../components";
import LiveActivityStats from "../dashboard/components/LiveActivityStats";
import useLiveTracking from "./hooks/useLiveTracking";
import Cookies from "js-cookie";
// import NewMap from "./components/NewMap";

const NewMap = lazy(() => import("./components/NewMap"));

export default function Tracking() {
  const role = Cookies.get("role");

  const [isClickedSearch, setIsClickedSearch] = useState(false);
  const {
    liveStats,
    checkboxSections,
    isLoadingLiveStats,
    allZoneListForSelection,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    liveStatsPayload,
    liveTrackingPayload,
    setLiveTrackingPayload,
    handleGetLiveStats,
    handleGetAllZones,
    handleGetPoliceStation,
    handleGetChildren,
    handleCheckboxChange,
    handleGetOtherFilterSelectionData,
  } = useLiveTracking();

  useEffect(() => {
    if (role?.includes("State")) {
      handleGetAllZones();
    }
    if (role?.includes("District")) {
      handleGetChildren(23, "subdivision");
    }
    if (role?.includes("SubDivision")) {
      handleGetPoliceStation(11);
    }
  }, []);

  useEffect(() => {
    handleGetLiveStats(liveStatsPayload, true);

    // Set up interval to call the function every 12 seconds
    const intervalId = setInterval(() => {
      handleGetLiveStats(liveStatsPayload, false);
    }, 10000);
    return () => clearInterval(intervalId);
    // }
  }, [isClickedSearch]);

  return (
    <div className="flex flex-col gap-4 h-full overflow-hidden">
      <EPageHeader>
        <EPageHeader.Title>Live Tracking</EPageHeader.Title>
        <EPageHeader.Right>
          {role?.includes("State") && (
            <ESelect
              marginTop={false}
              data={allZoneListForSelection}
              placeholder="All Zones"
              value={
                selectedZonesAndChildren.zones
                  ? String(selectedZonesAndChildren.zones)
                  : null
              }
              onChange={(e) => {
                handleGetOtherFilterSelectionData("range", e);
              }}
            />
          )}
          {role?.includes("State") && (
            <ESelect
              marginTop={false}
              data={allRangeListForSelection}
              value={
                selectedZonesAndChildren.ranges
                  ? String(selectedZonesAndChildren.ranges)
                  : null
              }
              disabled={selectedZonesAndChildren.zones ? false : true}
              placeholder="All range"
              onChange={(e) => {
                handleGetOtherFilterSelectionData("district", e);
              }}
            />
          )}
          {role?.includes("State") && (
            <ESelect
              marginTop={false}
              data={allDistrictsListForSelection}
              disabled={selectedZonesAndChildren.ranges ? false : true}
              value={
                selectedZonesAndChildren.districts
                  ? String(selectedZonesAndChildren.districts)
                  : null
              }
              placeholder="All Districts"
              onChange={(e) => {
                handleGetOtherFilterSelectionData("subdivision", e);
              }}
            />
          )}
          {role?.includes("State") || role?.includes("District") ? (
            <ESelect
              marginTop={false}
              placeholder="All Sub Divisions"
              data={allSubdivisionsListForSelection}
              disabled={
                selectedZonesAndChildren.districts || role?.includes("District")
                  ? false
                  : true
              }
              value={
                selectedZonesAndChildren.subDivisions
                  ? String(selectedZonesAndChildren.subDivisions)
                  : null
              }
              onChange={(e) => {
                handleGetOtherFilterSelectionData("policeStation", e);
              }}
            />
          ) : (
            <></>
          )}
          {role?.includes("State") ||
          role?.includes("District") ||
          role?.includes("SubDivision") ? (
            <ESelect
              marginTop={false}
              data={allPoliceStationsListForSelection}
              disabled={
                selectedZonesAndChildren.subDivisions ||
                role?.includes("SubDivision")
                  ? false
                  : true
              }
              value={
                selectedZonesAndChildren.policeStation
                  ? String(selectedZonesAndChildren.policeStation)
                  : null
              }
              placeholder="All Police Stations"
              onChange={(e) => {
                handleGetOtherFilterSelectionData("last", e);
              }}
            />
          ) : (
            <></>
          )}
          {role?.includes("State") ||
          role?.includes("District") ||
          role?.includes("SubDivision") ? (
            <>
              <EButton
                variant="outline"
                loading={isLoadingLiveStats}
                onClick={() => {
                  handleGetOtherFilterSelectionData("default", 1);
                  setIsClickedSearch(!isClickedSearch);
                }}
              >
                <EButton.Icon>
                  <ArrowPathIcon className="w-4 h-4" strokeWidth={2} />
                </EButton.Icon>
                <EButton.Label>Reset</EButton.Label>
              </EButton>
              <EButton
                variant="primary"
                disabled={false}
                onClick={() => {
                  setIsClickedSearch(!isClickedSearch);
                  // handleGetLiveStats(liveStatsPayload);
                }}
              >
                <EButton.Icon>
                  <ChevronRightIcon
                    className="w-4 h-4 text-white"
                    strokeWidth={3}
                  />
                </EButton.Icon>
              </EButton>
            </>
          ) : (
            <></>
          )}
        </EPageHeader.Right>
      </EPageHeader>

      {/* stats */}
      <LiveActivityStats
        isLoadingLiveStats={isLoadingLiveStats}
        liveStats={liveStats}
      />
      <div className="h-full grid grid-cols-12 border border-slate-200 rounded-lg">
        <div className="col-span-9">
          <Suspense fallback={<div>Loading...</div>}>
          <NewMap
            filter={liveTrackingPayload}
            handleClickSearch={isClickedSearch}
          />
          </Suspense>
        </div>
        <div className="col-span-3  border-l border-slate-200 px-6 flex flex-col justify-between">
          <div className="text-base font-medium border-b border-slate-200 py-3 ">
            Map Filters
          </div>
          <div className="py-5 w-full overflow-y-scroll max-h-[50vh] ">
            {checkboxSections.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                {section.sectionTitle && (
                  <div className="font-semibold text-sm text-slate-600 my-4">
                    {section.sectionTitle}
                  </div>
                )}
                {section.items.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <ECheckBox
                      size="sm"
                      label={item.label}
                      checked={item.checked}
                      onChange={() =>
                        handleCheckboxChange(sectionIndex, itemIndex)
                      }
                      iconEnd={
                        // <div
                        //   className={`w-3 h-3 rounded-full`}
                        //   style={{
                        //     backgroundColor: item.iconColor,
                        //   }}
                        // ></div>
                        <></>
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className="flex justify-end border-t border-slate-200 py-2">
            <div className="flex flex-row gap-2">
              <EButton
                variant="light"
                onClick={() => {
                  handleCheckboxChange(0, 0, true);
                }}
              >
                <EButton.Label>Clear</EButton.Label>
              </EButton>
              <EButton
                variant="primary"
                onClick={() => {
                  let pType: string[] = (
                    checkboxSections[1]?.items
                      ?.filter((item) => item.checked === true)
                      ?.map((item) => item.value) || []
                  ).filter((value): value is string => value !== undefined);

                  let cType: string[] = (
                    checkboxSections[2]?.items
                      ?.filter((item) => item.checked === true)
                      ?.map((item) => item.value) || []
                  ).filter((value): value is string => value !== undefined);

                  let cWays: string[] = (
                    checkboxSections[3]?.items
                      ?.filter((item) => item.checked === true)
                      ?.map((item) => item.value) || []
                  ).filter((value): value is string => value !== undefined);

                  setLiveTrackingPayload({
                    ...liveTrackingPayload,
                    patrollingTypes: pType,
                    convenienceWays: cWays,
                    checkPointTypes: cType,
                  });
                  setIsClickedSearch(!isClickedSearch);
                }}
              >
                <EButton.Label>Apply</EButton.Label>
              </EButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
