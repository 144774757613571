import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { RingChartLoader, ECard, ESegment } from "../../../components";
import useDashboard from "../hooks/useDashboard";
import { Skeleton } from "@mantine/core";
import NoDataRingChart from "../../../components/noDataChart/NoDataRingChart";
import { ApexOptions } from "apexcharts";

type IProps ={
  chartLoading : boolean;
  activeTab :"WEEK" | "MONTH" | "YEAR"
  setActiveTab : (e: "WEEK" | "MONTH" | "YEAR")=> void
  chartForNakkaStats : { series: number[]; options: ApexOptions }
  chartForPatrolStats : { series: number[]; options: ApexOptions }
}
export default function ActivityStats({
  chartLoading,
  activeTab,
  setActiveTab,
  chartForNakkaStats,
  chartForPatrolStats
}: IProps) {

  return (
    <ECard>
      <ECard.Head title="Activity Type">
        <ESegment.Wrapper>
          <ESegment
            label="This Week"
            isActive={activeTab == "WEEK" ? true : false}
            handleClick={() => setActiveTab("WEEK")}
          />
          <ESegment
            label="This Month"
            isActive={activeTab == "MONTH" ? true : false}
            handleClick={() => setActiveTab("MONTH")}
          />
          <ESegment
            label="This Year"
            isActive={activeTab == "YEAR" ? true : false}
            handleClick={() => setActiveTab("YEAR")}
          />
        </ESegment.Wrapper>
      </ECard.Head>
      <ECard.Body>
        {chartLoading ? (
          <div className="flex flex-row justify-around items-center gap-10 max-h-96">
            <div className="flex-1 pt-4  h-[30vh]">
              <RingChartLoader />
            </div>
            <div className="flex-1  pt-4  h-[30vh]">
              <RingChartLoader />
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-around gap-10 max-h-96">
            <div className="flex-1 h-[30vh]">
              {chartForPatrolStats.series.filter(e=>e == 0).length != chartForPatrolStats.series.length ?
                <ReactApexChart
                  options={chartForPatrolStats.options}
                  series={chartForPatrolStats.series}
                  type="pie"
                  height={"100%"}
                  width={"100%"}
                />
                :
                <>
                <NoDataRingChart />
                <p>No data</p>
                 </>
                }
            </div>
            <div className="flex-1">
             { 
              chartForNakkaStats?.series?.filter(e=>e == 0).length != chartForNakkaStats?.series?.length ?  
               <ReactApexChart
                options={chartForNakkaStats.options}
                series={chartForNakkaStats.series}
                type="pie"
                height={"100%"}
                width={"100%"}
              />
               :
              <NoDataRingChart /> 
            }
            </div>
          </div>
        )}
      </ECard.Body>
    </ECard>
  );
}
