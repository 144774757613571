import { useEffect, useState } from "react";
import {
  EButton,
  EPageHeader,
  ESpacer,
  ESelect,
} from "../../components";
import { ArrowPathIcon, ChevronRightIcon,} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import useDashboard from "./hooks/useDashboard";
import ActivityStats from "./components/ActivityStats";
import DistrictWiseBreakDownStats from "./components/DistrictWiseBreakDownStats";
import Cookies from "js-cookie";
import { MapPinIcon } from "@heroicons/react/24/solid";

export default function Dashboard() {
  const navigate = useNavigate();
  const {
    isLoadingChartForPatrolAndNakkaStats,
    activeTabActivityType,
    setActiveTabActivityType,
    chartForNakkaStats,
    chartForPatrolStats,
    handleGetActivityTypeStats,
    allZoneListForSelection,
    allRangeListForSelection,
    allDistrictsListForSelection,
    allSubdivisionsListForSelection,
    allPoliceStationsListForSelection,
    selectedZonesAndChildren,
    handleGetAllZones,
    handleGetLiveStats,
    handleGetChildren,
    handleGetOtherFilterSelectionData,
    handleGetPoliceStation
  } = useDashboard();

  const [isClickedSearch, setIsClickedSearch] = useState(false);

  const role = Cookies.get("role");

  useEffect(() => {
    if (role?.includes("State")) {
      handleGetAllZones()
    }
    if (role?.includes("District")) {
      handleGetChildren(23, "subdivision")
    }
    if (role?.includes("SubDivision")) {
      handleGetPoliceStation(11)
    }
  }, [])

  useEffect(() => {
    handleGetActivityTypeStats();
  }, [activeTabActivityType, isClickedSearch]);


  return (
    <>
      <div className=" flex flex-col gap-4">
        <EPageHeader>
          <EPageHeader.Title>Dashboard</EPageHeader.Title>
          <EPageHeader.Right>
            {role?.includes("State") &&
              <ESelect
                marginTop={false}
                data={allZoneListForSelection}
                placeholder="All Zones"
                value={selectedZonesAndChildren.zones ? String(selectedZonesAndChildren.zones) : null}
                onChange={(e) => {
                  handleGetOtherFilterSelectionData("range", e);
                }}
              />}
            {role?.includes("State") &&
              <ESelect
                marginTop={false}
                data={allRangeListForSelection}
                value={selectedZonesAndChildren.ranges ? String(selectedZonesAndChildren.ranges) : null}
                disabled={selectedZonesAndChildren.zones ? false : true}
                placeholder="All range"
                onChange={(e) => {
                  handleGetOtherFilterSelectionData("district", e);
                }}
              />}
            {role?.includes("State") &&
              <ESelect
                marginTop={false}
                data={allDistrictsListForSelection}
                disabled={selectedZonesAndChildren.ranges ? false : true}
                value={selectedZonesAndChildren.districts ? String(selectedZonesAndChildren.districts) : null}
                placeholder="All Districts"
                onChange={(e) => {
                  handleGetOtherFilterSelectionData("subdivision", e);
                }}
              />
            }
            {role?.includes("State") || role?.includes("District") ?
              <ESelect
                marginTop={false}
                placeholder="All Sub Divisions"
                data={allSubdivisionsListForSelection}
                disabled={selectedZonesAndChildren.districts || role?.includes("District") ? false : true}
                value={selectedZonesAndChildren.subDivisions ? String(selectedZonesAndChildren.subDivisions) : null}
                onChange={(e) => {
                  handleGetOtherFilterSelectionData("policeStation", e);
                }}
              /> : <></>
            }
            {role?.includes("State") || role?.includes("District") || role?.includes("SubDivision") ?
              <ESelect
                marginTop={false}
                data={allPoliceStationsListForSelection}
                disabled={selectedZonesAndChildren.subDivisions || role?.includes("SubDivision") ? false : true}
                value={selectedZonesAndChildren.policeStation ? String(selectedZonesAndChildren.policeStation) : null}
                placeholder="All Police Stations"
                onChange={(e) => {
                  handleGetOtherFilterSelectionData("last", e);
                }}
              /> : <></>
            }
            {
              role?.includes("State") || role?.includes("District") || role?.includes("SubDivision") ?
                <>
                  <EButton
                    variant="outline"
                    onClick={() => {
                      handleGetOtherFilterSelectionData("default", 1);
                      // handleGetActivityTypeStats()
                      setIsClickedSearch(!isClickedSearch);
                    }}
                  >
                    <EButton.Icon>
                      <ArrowPathIcon
                        className="w-4 h-4"
                        strokeWidth={2}
                      />
                    </EButton.Icon>
                    <EButton.Label>
                      Reset
                    </EButton.Label>
                  </EButton>

                  <EButton
                    variant="primary"
                    disabled={false}
                    onClick={() => {
                      // handleGetActivityTypeStats()
                      setIsClickedSearch(!isClickedSearch);
                    }}
                  >
                  {/* <EButton.Label>Update</EButton.Label> */}
                    <EButton.Icon>
                      <ChevronRightIcon
                        className="w-4 h-4 text-white"
                        strokeWidth={3}
                      />
                    </EButton.Icon>
                  </EButton>
                </>
                : <></>
            }
          </EPageHeader.Right>
        </EPageHeader>

        {/* <LiveActivityStats handleClickSearch={isClickedSearch}/> */}
        <div
          onClick={() => {
            navigate("tracking");
          }}
          className="bg-live-tracking h-[140px] bg-slate-200 rounded-md overflow-hidden relative"
        >
          <div className=" transition-all absolute top-0 left-0 right-0 bottom-0 bg-black/20 flex items-center justify-center hover:bg-black/5 ">
            <EButton variant="secondary" onClick={() => { }}>
              <EButton.Label>View Live Tracking</EButton.Label>
              <EButton.Icon>
                <MapPinIcon className="w-5 h-5" />
              </EButton.Icon>
            </EButton>
          </div>
        </div>

        <ActivityStats
          chartLoading={isLoadingChartForPatrolAndNakkaStats}
          activeTab={activeTabActivityType}
          setActiveTab={(e) => setActiveTabActivityType(e)}
          chartForNakkaStats={chartForNakkaStats}
          chartForPatrolStats={chartForPatrolStats}
        />
        { !role?.includes("District") ? <DistrictWiseBreakDownStats handleClickSearch={isClickedSearch} /> : <></>}
        <ESpacer />
      </div>
    </>
  );
}
