import React, { useEffect } from 'react'
import { IUserManagementProfile } from '../../../../libs/data-access/types'
import { showToast } from '../../../../libs/data-access/utility';
import { usePolicePersonnel } from '../../../../libs/data-access';
import { useNavigate, useParams } from 'react-router-dom';

type IUser = {
    personalInformation: {
        label: string;
        key: keyof IUserManagementProfile;
        value: string;
    }[],
    contactInformation: {
        label: string;
        key: keyof IUserManagementProfile;
        value: string;
    }[],
}
export default function useUserManagementProfile() {
    const { postPolicePersonalData } = usePolicePersonnel();
    const navigate = useNavigate();
    const param = useParams();

    const [isLoading, setIsLoading] = React.useState(false)
    const [userData, setUserData] = React.useState<IUserManagementProfile>({} as IUserManagementProfile);
    const [activeTab, setActiveTab] = React.useState<"profile"|"patrol"|"achievement"|"transfer">("profile")

    //   React.useState<IUserManagementProfile>({}as IUserManagementProfile);

    useEffect(() => {
        getUserDetailsById()
    }, [])

    console.log(userData)

    const getUserDetailsById = async () => {
        setIsLoading(true);
        const response = await postPolicePersonalData(`get-police-personnel-profile?policePersonId=${param?.id}`, {})
        if (response.isSuccess) {
            setUserData(response.data);
        }
        else {
            showToast({
                type: "error",
                title: "Fail to get user details",
                message: response?.message || "Fail to get user details",
            })
            navigate('/user-management')
        }
        setIsLoading(false);
    }
    return {
        isLoading,
        userData,
        activeTab,
        setActiveTab,
    }
}
