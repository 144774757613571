import Cookies from "js-cookie";
import { useState } from "react";
import {
  ILiveStatsExtraDetails,
  ILoggedInUserInfo,
} from "../libs/data-access/types";
import { IDefaultCenter, ILiveStatsPayload, IRole } from "../utils/types";
import { useNavigate } from "react-router-dom";

export default function useApp() {
  const navigate = useNavigate();
  const [role, setRole] = useState<IRole[] | undefined>(["District"]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(false);
  const [token, setToken] = useState<string | undefined>();
  const [refreshToken, setRefreshToken] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState<ILoggedInUserInfo>();
  const [centerLatAndLong, setCenterLatAndLong] = useState<
    IDefaultCenter | undefined
  >({
    lat: 0,
    lng: 0,
  });
  const [firebaseDeviceToken, setFirebaseDeviceToken] = useState<string>("");
  const [sosActive, setSosActive] = useState<any>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(13);
  const handleCheckIsLoggedIn = () => {
    setIsLoading(true);
    const token = Cookies.get("token");
    const isLoggedIn = Cookies.get("isLoggedIn");
    const refreshToken = Cookies.get("refreshToken");
    const loggedInUserInfo = Cookies.get("loggedInUser");

    const loggedInUser = JSON.parse(loggedInUserInfo || "{}");
    if (loggedInUser) {
      setLoggedInUserInfo(loggedInUser);
    }
    setToken(token);
    setRefreshToken(refreshToken);
    setIsLoggedIn(isLoggedIn === "true" ? true : false);
    setIsLoading(false);
  };

  const handleLogOut = () => {
    Cookies.remove("token");
    Cookies.remove("role");
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
    Cookies.remove("loggedInUser");
    setToken(undefined);
    setRefreshToken(undefined);
    setIsLoggedIn(false);
    setLoggedInUserInfo(undefined);
    navigate("/");
  };

  return {
    role,
    token,
    isLoading,
    zoomLevel,
    sosActive,
    isLoggedIn,
    refreshToken,
    firebaseDeviceToken,
    centerLatAndLong,
    loggedInUserInfo,
    setRole,
    setToken,
    setZoomLevel,
    setSosActive,
    setIsLoading,
    setIsLoggedIn,
    setRefreshToken,
    setFirebaseDeviceToken,
    setCenterLatAndLong,
    setLoggedInUserInfo,
    handleCheckIsLoggedIn,
    handleLogOut,
  };
}
