import { useParams } from "react-router-dom";
import { usePolicePersonnel } from "../../../../libs/data-access";
import { showToast } from "../../../../libs/data-access/utility";

export default function useTransfer() {
  const { patchPolicePersonalData } = usePolicePersonnel();
  const param = useParams();

  const handleInitiateTransfer = async () => {
    let res = await patchPolicePersonalData(
      `transfer-initiated?policePersonIds=${param?.id}`,
      {}
    );

    if (res?.isSuccess) {
      showToast({
        type: "success",
        title: "Transfer Initiated Successfully",
        message: res.message,
      });
    } else {
    }
  };

  return {
    handleInitiateTransfer,
  };
}
