import React, { ElementType, ReactNode } from "react";

interface EStatProps {
  children: React.ReactNode; // Use React.ReactNode for children
  color?: "blue" | "indigo" | "green" | "amber" | "slate" | "sky";
}

const colorClasses = {
  blue: { background: "bg-blue-500/5", border: "bg-blue-500" },
  indigo: { background: "bg-indigo-500/5", border: "bg-indigo-500" },
  green: { background: "bg-emerald-500/5", border: "bg-emerald-500" },
  amber: { background: "bg-amber-500/5", border: "bg-amber-500" },
  slate: { background: "bg-slate-500/5", border: "bg-slate-500" },
  sky: { background: "bg-sky-500/5", border: "bg-sky-500" },
};

const EStat: React.FC<EStatProps> & {
  Label: React.FC<{ children: ReactNode }>;
  Stat: React.FC<{ children: ReactNode }>;
} = ({ children, color = "blue" }) => {
  const { background, border } = colorClasses[color];
  return (
    <div
      className={`relative flex items-center justify-between ${background} rounded-md py-3 px-5 border select-none flex-shrink-0`}
    >
      <div
        className={`absolute w-1.5 rounded-r-md h-10 top-1/2 -left-0.5 -mt-5 ${border}`}
      />
      {children}
    </div>
  );
};

// Define Stat.Label
EStat.Label = ({ children }) => <span className="font-medium">{children}</span>;

// Define Stat.Icon
EStat.Stat = ({ children }) => (
  <div className="text-2xl font-bold">{children}</div>
);

export default EStat;
