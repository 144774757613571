import axios from "axios";
import { API_URL } from "../../constant";
import { res } from "../../types";
import { handleFormatResponse } from "../../utility";

export default function useMedia() {
  let r = res;

  const postMediaData = async (endPoint: string, payload: any) => {
    try {
      let response = await axios.post(`${API_URL}${endPoint}`, payload);
      // const formattedResponse = handleFormatResponse(response);
      const formattedResponse = {
        isSuccess: true,
        data: response?.data,
        message: "success",
      };
      return formattedResponse;
    } catch (error: any) {
      return (r = {
        isSuccess: false,
        data: undefined,
        error: error?.response?.data,
        message: error?.response?.data?.detail || "Request Failed",
      });
    }
  };

  const getMediaData = async (endPoint: string) => {
    try {
      let response = await axios.get(`${API_URL}${endPoint}`);
      const formattedResponse = {
        isSuccess: true,
        data: response?.data,
        message: "success",
      };
      return formattedResponse;
    } catch (error: any) {
      return (r = {
        isSuccess: false,
        data: undefined,
        error: error?.response?.data,
        message: error?.response?.data?.detail || "Request Failed",
      });
    }
  };

  return {
    postMediaData,
    getMediaData,
  };
}
