import React from "react";

interface IProps {
  title: string;
  classes?: string;
  titleStyle?: string;
  subTitle?: string;
  end?: React.ReactNode;
  noBorder?: boolean;
}

export function ESectionDivider({
  title,
  classes,
  titleStyle,
  end,
  noBorder,
  subTitle,
}: IProps) {
  return (
    <div>
      <div
        className={`flex items-center justify-between ${
          subTitle ? "mb-1" : "mb-4"
        } ${classes && classes}`}
      >
        <div
          className={`capitalize ${
            titleStyle ? titleStyle : "text-2xl font-bold mr-4"
          }`}
        >
          {title}
        </div>
        {noBorder ? <></> : <div className="flex-1 h-[1px] bg-slate-200"></div>}
        {end && <div className="pl-3">{end}</div>}
      </div>
      {subTitle && (
        <div className={`text-sm text-slate-600 font-normal mb-4`}>
          {subTitle}
        </div>
      )}
    </div>
  );
}
