import React from "react";
import { EPageHeader } from "../../components";
import ReportCard from "./components/ReportCard";

export default function ReportAnalysis() {
  const patrolData = [
    {
      title: "Patrol History",
      subtitle: "View past patrol details",
    },
    {
      title: "Top Patrol Men",
      subtitle: "View patrol count per person",
    },
    {
      title: "District-Wise Report",
      subtitle: "District wise patrol report",
    },
    {
      title: "App Users",
      subtitle: "View all app users",
    },
  ];

  const nakkaData = [
    {
      title: "Nakka History",
      subtitle: "View past nakka details",
    },
    {
      title: "Top Nakka Men",
      subtitle: "View nakka count per person",
    },
    {
      title: "District-Wise Report",
      subtitle: "District wise nakka report",
    },
  ];

  return (
    <div className="flex flex-col gap-4 h-full overflow-hidden">
      <EPageHeader>
        <EPageHeader.Title>Reports & Analysis</EPageHeader.Title>
      </EPageHeader>

      <div className="text-xl font-bold">Patrol Reports</div>

      <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-5 gap-2 mb-4">
        {patrolData.map((item, index) => (
          <ReportCard key={index} title={item.title} subTitle={item.subtitle} />
        ))}
      </div>

      <div className="text-xl font-bold">Nakka Reports</div>

      <div className="grid grid-cols-2  md:grid-cols-4 lg:grid-cols-5 gap-2">
        {nakkaData.map((item, index) => (
          <ReportCard key={index} title={item.title} subTitle={item.subtitle} />
        ))}
      </div>
    </div>
  );
}
